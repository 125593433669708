export const P3_1 = ({ fill }) => {
  return (
    <svg
      width="66"
      height="77"
      viewBox="0 0 66 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1702 76.7893L0.760254 65.2794L15.1302 0.0693359L66.0002 10.7293L51.1702 76.7893Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_2 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="76"
      viewBox="0 0 61 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.76 75.2792L0 65.6191L14.43 0.369141L60.13 10.0692L45.76 75.2792Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_3 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="75"
      viewBox="0 0 61 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.0002 74.62L0.700195 65.07L15.6702 0L60.4302 9.37L46.0002 74.62Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_4 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="77"
      viewBox="0 0 61 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7 76.0696L60.67 10.9996L14.86 0.30957L0.75 66.3596L45.7 76.0696Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_5 = ({ fill }) => {
  return (
    <svg
      width="60"
      height="77"
      viewBox="0 0 60 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7499 76.36L59.8599 10.3101L15.3299 0.330078L0.859863 66.7501L45.7499 76.36Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_6 = ({ fill }) => {
  return (
    <svg
      width="59"
      height="76"
      viewBox="0 0 59 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.8601 75.7496C-16.4499 63.3796 -0.399829 77.5097 11.7102 0.0196533L58.3302 9.33966L43.8601 75.7596V75.7496Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_7 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="74"
      viewBox="0 0 61 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.0399 10.2399C41.6199 82.2699 62.5299 79.5899 0.169922 66.8699L14.58 0.669922L60.0399 10.2499V10.2399Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_8 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="74"
      viewBox="0 0 61 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1802 6.14938C13.7902 3.52938 16.2702 1.82933 18.8202 1.15933C19.6302 0.939326 20.4802 0.879338 21.3202 0.859338C22.0402 0.849338 22.7502 0.91935 23.4502 1.05935L60.6202 8.67935L47.3202 73.3394L0.700195 64.0194L13.1702 6.15933L13.1802 6.14938Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_9 = ({ fill }) => {
  return (
    <svg
      width="60"
      height="76"
      viewBox="0 0 60 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.330078 65.33L13.6301 0.669922L59.3101 11.15L44.86 75.3099L0.330078 65.33Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_10 = ({ fill }) => {
  return (
    <svg
      width="61"
      height="75"
      viewBox="0 0 61 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.859863 64.3094L15.3099 0.149414L60.7699 9.72937L46.6699 74.9994L0.859863 64.3094Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_11 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="86"
      viewBox="0 0 92 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7702 0.729614C22.9302 2.29961 33.2902 4.38965 41.7202 6.01965C42.6602 6.19965 43.5702 6.46961 44.4702 6.78961C49.4602 8.52961 55.0802 9.27964 60.2602 8.25964L91.1402 85.0797L0.680176 66.0096L14.7802 0.739624L14.7702 0.729614Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_12 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="105"
      viewBox="0 0 99 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 17.2492C8.79 14.8992 16.32 8.38918 19.66 0.15918L98.3 30.4892L82 104.729L31.13 94.0692L0.25 17.2492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_13 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="75"
      viewBox="0 0 92 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.660156 44.1595L16.5202 0.939575L91.8502 17.4896L79.3002 74.4896L0.660156 44.1595Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_14 = ({ fill }) => {
  return (
    <svg
      width="88"
      height="67"
      viewBox="0 0 88 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.52002 49.9396L11.95 0.229614L87.4 16.9597L75.85 66.4896L0.52002 49.9396Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_15 = ({ fill }) => {
  return (
    <svg
      width="89"
      height="63"
      viewBox="0 0 89 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.950195 46.2296L8.5802 8.83966L6.42017 0.719604L88.9102 1.72961L76.4002 62.9597L0.950195 46.2296Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_16 = ({ fill }) => {
  return (
    <svg
      width="101"
      height="71"
      viewBox="0 0 101 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4202 69.7198L0.430176 24.7198L89.5502 0.129883C89.7402 0.649883 89.9602 1.20986 90.2102 1.78986C91.9202 5.85986 92.3302 10.3199 91.5502 14.6599C90.7702 18.9999 90.2902 23.4599 90.3702 27.4499C90.4202 30.0599 90.4602 30.8199 91.6502 32.4099C95.0302 36.5499 99.0702 42.8599 100.54 48.0799L94.9302 70.7299L12.4302 69.7099L12.4202 69.7198Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_17 = ({ fill }) => {
  return (
    <svg
      width="102"
      height="67"
      viewBox="0 0 102 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.43 66.7196L0.27002 22.1796L78.36 0.519653C79.1 14.4197 82.47 16.5096 88.99 24.6996C90.98 27.1996 92.99 29.6896 95.17 32.0396C97.93 35.0196 100.46 38.6796 101.55 42.1396L12.43 66.7296V66.7196Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_18 = ({ fill }) => {
  return (
    <svg
      width="106"
      height="77"
      viewBox="0 0 106 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.140137 33.3996L95.3601 0.00964355C120.19 40.9796 90.2101 23.4396 96.3701 54.5096L18.2802 76.1696C17.0702 65.2796 5.54015 42.8996 0.150146 33.3996H0.140137Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_25 = ({ fill }) => {
  return (
    <svg
      width="65"
      height="101"
      viewBox="0 0 65 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.41 0.239258L64.74 9.72925L45.74 100.819L0 90.2493L19.41 0.239258Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_26 = ({ fill }) => {
  return (
    <svg
      width="66"
      height="102"
      viewBox="0 0 66 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.27 11.3496L45.29 101.26L0.73999 91.8196L19.74 0.729614L65.27 11.3496Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_27 = ({ fill }) => {
  return (
    <svg
      width="98"
      height="61"
      viewBox="0 0 98 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.29 17.1396C73.33 78.1297 95.25 61.9396 0.290039 44.2496L9.95007 0.00964355L97.29 17.1296V17.1396Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_28 = ({ fill }) => {
  return (
    <svg
      width="98"
      height="64"
      viewBox="0 0 98 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.9402 18.5297L88.2902 63.1396L0.950195 46.0197L11.2702 0.349609L97.9402 18.5297Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_29 = ({ fill }) => {
  return (
    <svg
      width="95"
      height="64"
      viewBox="0 0 95 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.919922 5.14966L88.3099 0.719604C90.4899 7.8496 93.5399 16.8096 94.8599 24.1396C92.7599 33.9697 88.5499 53.9597 85.9399 63.5197L10.6199 47.6996L0.919922 5.13965V5.14966Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_30 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="66"
      viewBox="0 0 99 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.990234 23.1492L82.0002 0.15918L98.3102 60.7192L10.9202 65.1492L0.990234 23.1492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_31 = ({ fill }) => {
  return (
    <svg
      width="91"
      height="78"
      viewBox="0 0 91 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.900146 36.7292L69.5501 0.699219C73.3801 7.85922 81.1702 22.3592 85.0302 29.5292C86.5402 34.4092 89.8201 48.9792 91.0001 54.1592L9.99011 77.1492L0.890137 36.7292H0.900146Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_32 = ({ fill }) => {
  return (
    <svg
      width="99"
      height="83"
      viewBox="0 0 99 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.790039 46.4592L29.8901 82.7292L98.54 46.6992L85.8101 19.2092C83.1801 17.8092 66.1601 1.14919 59.8201 0.479187C59.0601 0.399187 58.18 0.419188 57.3 0.449188C55.28 0.509188 53.3601 1.30918 51.8301 2.62918C42.7501 10.4292 0.800049 46.4692 0.800049 46.4692L0.790039 46.4592Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_35 = ({ fill }) => {
  return (
    <svg
      width="106"
      height="97"
      viewBox="0 0 106 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.9 57.1996L76.55 96.9095L0.969971 28.2495L40.88 0.0195312C44.08 3.80953 49.35 5.98954 53.96 7.43954C55.95 8.05954 58.92 8.51953 60.97 8.73953C64.19 12.4195 106.29 53.2196 105.9 57.2096V57.1996Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_36 = ({ fill }) => {
  return (
    <svg
      width="130"
      height="166"
      viewBox="0 0 130 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.47998 4.84958L27.8 0.189575L129.84 114.88C129.07 115.65 127.19 117.05 126.63 117.83C122.57 123.59 123.59 126.57 124.88 130.7C125.46 132.55 126.88 137 126.88 137L86.9699 165.23L48.7899 131.77L0.469971 4.82956L0.47998 4.84958Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_37 = ({ fill }) => {
  return (
    <svg
      width="136"
      height="123"
      viewBox="0 0 136 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.8798 0.979492L135.73 106.049L123.55 121.689C121.96 120.869 120.8 120.059 116.91 119.559C112.43 118.989 108.98 119.489 105.32 121.289C104.16 121.859 102.84 122.879 102.84 122.879L0.799805 8.18948L62.8798 0.979492Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_38 = ({ fill }) => {
  return (
    <svg
      width="102"
      height="94"
      viewBox="0 0 102 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.69 32.5895C102.24 34.5995 102.06 37.1295 101.34 39.5195C100.7 41.6195 99.4299 43.4696 97.7599 44.8896L41.9199 92.4095C41.0599 93.0895 40.3299 93.4895 39.6299 93.5595C37.9699 93.7295 36.1299 93.7096 34.3799 93.4496C32.9599 93.2396 31.6 92.7395 30.34 92.0295C29.73 91.6795 29.1699 91.2495 28.6799 90.7395L0.169922 61.1195L77.52 0.0195312L100.68 32.5995L100.69 32.5895Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_39 = ({ fill }) => {
  return (
    <svg
      width="91"
      height="101"
      viewBox="0 0 91 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.5302 39.0101L13.1801 100.11C13.5301 95.4601 7.92012 83.5401 3.56012 81.6901L15.7401 66.0501L0.850098 44.6801L65.0801 0.0800781L90.5302 39.0201V39.0101Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_40 = ({ fill }) => {
  return (
    <svg
      width="95"
      height="87"
      viewBox="0 0 95 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.0698 42.0792L64.6498 0.40918L0.839844 44.9492L29.8398 86.6792L94.0698 42.0792Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_41 = ({ fill }) => {
  return (
    <svg
      width="94"
      height="88"
      viewBox="0 0 94 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.6501 43.4096L65.0701 0.479614L0.880127 45.9796L29.8401 87.9496L93.6501 43.4096Z"
        fill="#878A8E"
      />
    </svg>
  );
};

export const P3_42 = ({ fill }) => {
  return (
    <svg
      width="80"
      height="101"
      viewBox="0 0 80 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.0702 55.4796L14.8902 100.98L0.200195 0.219604C3.8702 1.8096 66.9202 15.0496 71.5302 18.4196C72.7702 19.3296 73.7002 20.7096 73.6302 22.2396C74.0102 22.0796 70.2702 40.3296 70.2002 40.9396C70.0102 41.7996 69.6802 43.1196 70.1602 43.9396C70.9702 45.0196 78.0902 54.1896 79.0702 55.4696V55.4796Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_43 = ({ fill }) => {
  return (
    <svg
      width="63"
      height="119"
      viewBox="0 0 63 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.1898 10.2198L62.8798 110.98L0.799805 118.19L2.6098 0.549805H4.58984L48.1898 10.2198Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_44 = ({ fill }) => {
  return (
    <svg
      width="53"
      height="127"
      viewBox="0 0 53 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97015 0.949219L52.6101 4.54922L50.8001 122.189L23.4802 126.849L0.160156 87.9392L4.97015 0.949219Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_45 = ({ fill }) => {
  return (
    <svg
      width="167"
      height="221"
      viewBox="0 0 167 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0600586 179.76L47.9101 220.59L63.7901 123.73L166.69 28.4996L147.28 19.0696L31.3101 0.349609L0.0600586 179.76Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_46 = ({ fill }) => {
  return (
    <svg
      width="155"
      height="211"
      viewBox="0 0 155 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.910156 192.59L48.3202 210.62L59.7002 202.78L62.6002 122.25L154.13 38.9L119.69 0.5L16.7901 95.73L0.910156 192.59Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_48 = ({ fill }) => {
  return (
    <svg
      width="84"
      height="140"
      viewBox="0 0 84 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3701 139.319L53.9601 118.009L75.1301 85.9692L83.1301 42.9293L46.4301 0.529297V35.1493L32.5501 69.2493L0.0800781 98.4193V106.169L17.0201 125.929L35.3701 139.319Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_49 = ({ fill }) => {
  return (
    <svg
      width="97"
      height="163"
      viewBox="0 0 97 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.72 27.2501L50.78 0.620117L0.990021 32.8701L33.2 70.0901L25.2 113.12L0.77002 150.09L24.43 162.81L96.14 49.7801L55.72 27.2501Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_50 = ({ fill }) => {
  return (
    <svg
      width="144"
      height="149"
      viewBox="0 0 144 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.200195 139.17L10.3702 113.66L82.0802 0.629883L143.62 37.4099L64.6702 78.5399L20.5402 148.19L0.200195 139.17Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_51 = ({ fill }) => {
  return (
    <svg
      width="124"
      height="125"
      viewBox="0 0 124 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.540039 111.189L44.67 41.5392L123.62 0.40918L45.87 124.209L0.540039 111.189Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_52 = ({ fill }) => {
  return (
    <svg
      width="92"
      height="113"
      viewBox="0 0 92 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.870117 57.2092L37.4601 0.369141L91.5401 49.8091L54.1001 112.889L45.3501 105.769L37.2701 101.169L29.2101 96.7592L26.3401 95.0292L19.6201 82.3691L14.8201 73.3491L11.5601 67.7891L7.53012 62.9891L0.870117 57.2092Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_53 = ({ fill }) => {
  return (
    <svg
      width="104"
      height="115"
      viewBox="0 0 104 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.100098 63.8896L13.1501 74.3996L23.0901 81.3096L30.0601 90.3596L33.1801 97.0696L34.7101 101.29L36.0501 103.66L89.1701 114.86L103.58 48.6595L37.5401 0.80957L0.100098 63.8896Z"
        fill={fill}
      />
    </svg>
  );
};

export const P3_54 = ({ fill }) => {
  return (
    <svg
      width="139"
      height="106"
      viewBox="0 0 139 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2102 0.449219L0.510254 75.5292L138.9 105.379L130.05 93.3992L124.71 86.2892L118.72 79.5892L90.2903 57.7992L68.3402 44.4092L53.4002 19.6092L45.2402 10.4692L16.2102 0.449219Z"
        fill={fill}
      />
    </svg>
  );
};

// export const P3_68 = ({ fill }) => {
//   return (
//     <svg
//       width="144"
//       height="154"
//       viewBox="0 0 144 154"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M47.52 33.69L105.54 0.369995L143.91 22.48L62.81 153.32L27.58 132.73L0.98999 106.76V97.36L35.55 60.68L47.52 33.69Z"
//         fill={fill}
//       />
//     </svg>
//   );
// };

// export const P3_69 = ({ fill }) => {
//   return (
//     <svg
//       width="144"
//       height="162"
//       viewBox="0 0 144 162"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M81.91 0.47998L143.73 38.68L67.95 161.35L0.809998 131.32L81.91 0.47998Z"
//         fill={fill}
//       />
//     </svg>
//   );
// };

export const BordersPhase3 = () => {
  return (
    <svg
      width="788"
      height="871"
      viewBox="0 0 788 871"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M589.33 373.69C589.26 373.69 589.19 373.67 589.13 373.63C589.04 373.57 588.99 373.48 588.97 373.37C587.8 362.81 576.63 340.87 570.88 330.74C570.83 330.65 570.82 330.54 570.86 330.44C570.9 330.34 570.98 330.26 571.08 330.23L666.3 296.84C666.46 296.78 666.64 296.84 666.73 296.99C680.42 319.58 677.83 324.39 672.48 330.38C669.06 334.2 665.19 338.53 667.78 351.6C667.82 351.78 667.7 351.97 667.52 352.02L589.43 373.68C589.43 373.68 589.37 373.69 589.33 373.69ZM571.72 330.75C577.47 340.95 588.23 362.17 589.63 372.87L667 351.41C664.47 338.24 668.43 333.81 671.93 329.89C676.54 324.73 680.18 320.66 666.25 297.6L571.71 330.75H571.72Z"
        fill="white"
      />
      <path
        d="M601.49 418.23C601.43 418.23 601.37 418.21 601.31 418.18C601.23 418.13 601.17 418.06 601.14 417.96L588.98 373.42C588.93 373.23 589.04 373.03 589.23 372.98L667.32 351.32C667.43 351.29 667.54 351.32 667.63 351.37C667.72 351.43 667.77 351.53 667.78 351.64C668.42 363.58 670.92 366.61 675.91 372.64C676.66 373.55 677.47 374.53 678.33 375.61C680.24 378.01 682.28 380.55 684.49 382.93C686.32 384.91 689.6 388.88 690.95 393.16C690.98 393.25 690.97 393.35 690.92 393.44C690.87 393.53 690.79 393.59 690.7 393.61L601.58 418.2C601.58 418.2 601.52 418.21 601.48 418.21L601.49 418.23ZM589.77 373.58L601.74 417.43L690.14 393.04C689.12 390.05 686.93 386.66 683.95 383.44C681.73 381.04 679.67 378.49 677.76 376.08C676.9 375 676.09 374.03 675.34 373.12C670.47 367.24 667.77 363.96 667.08 352.15L589.77 373.59V373.58Z"
        fill="white"
      />
      <path
        d="M695.97 464.25L613.47 463.23C613.31 463.23 613.17 463.12 613.13 462.96L601.14 417.96C601.09 417.77 601.2 417.57 601.39 417.52L690.51 392.93C690.7 392.88 690.88 392.98 690.94 393.16C691.13 393.68 691.35 394.23 691.59 394.8C693.29 398.84 693.76 403.37 692.95 407.88C692.12 412.47 691.71 416.99 691.78 420.6C691.83 423.25 691.88 423.87 692.99 425.35C696.16 429.24 700.41 435.73 701.93 441.14C701.95 441.2 701.95 441.26 701.93 441.33C701.9 441.44 696.31 463.98 696.31 463.98C696.27 464.14 696.13 464.25 695.96 464.25H695.97ZM613.76 462.51L695.7 463.52L700.37 444.69C700.85 442.76 701.09 441.8 701.25 441.32C699.76 436.01 695.57 429.64 692.44 425.79C691.15 424.06 691.13 423.19 691.08 420.61C690.99 415.92 691.67 411.03 692.26 407.75C693.05 403.38 692.59 398.99 690.94 395.08C690.74 394.61 690.56 394.16 690.39 393.72L601.94 418.13L613.77 462.52L613.76 462.51Z"
        fill="white"
      />
      <path
        d="M683.46 525.47C683.46 525.47 683.41 525.47 683.38 525.47L607.93 508.74C607.74 508.7 607.62 508.51 607.66 508.32L615.28 471.02L613.13 462.98C613.1 462.87 613.13 462.76 613.19 462.67C613.26 462.58 613.37 462.54 613.48 462.53L695.98 463.55C696.09 463.55 696.19 463.6 696.26 463.68C696.33 463.76 696.35 463.87 696.33 463.98L683.82 525.2C683.8 525.29 683.74 525.38 683.66 525.43C683.6 525.47 683.53 525.49 683.46 525.49V525.47ZM608.44 508.11L683.19 524.68L695.54 464.24L613.95 463.24L616 470.91C616.01 470.96 616.02 471.02 616 471.08L608.44 508.12V508.11Z"
        fill="white"
      />
      <path
        d="M671.91 575.01C671.91 575.01 671.86 575.01 671.83 575.01L596.5 558.46C596.41 558.44 596.32 558.38 596.27 558.3C596.22 558.22 596.2 558.12 596.22 558.03L607.65 508.32C607.69 508.13 607.88 508.01 608.08 508.05L683.53 524.78C683.62 524.8 683.71 524.86 683.76 524.94C683.81 525.02 683.83 525.12 683.81 525.21L672.26 574.75C672.22 574.92 672.07 575.03 671.91 575.03V575.01ZM597.01 557.82L671.64 574.22L683.03 525.38L608.28 508.81L597.01 557.82Z"
        fill="white"
      />
      <path
        d="M659.36 632C659.36 632 659.27 632 659.23 631.98L580.6 601.65C580.42 601.58 580.32 601.37 580.39 601.19L596.24 557.98C596.3 557.81 596.48 557.71 596.66 557.75L671.99 574.3C672.08 574.32 672.16 574.38 672.22 574.46C672.27 574.54 672.29 574.64 672.27 574.73L659.72 631.72C659.7 631.82 659.63 631.91 659.54 631.96C659.49 631.99 659.43 632 659.37 632H659.36ZM581.19 601.1L659.1 631.15L671.48 574.92L596.81 558.51L581.19 601.1Z"
        fill="white"
      />
      <path
        d="M643.06 706.25C643.06 706.25 643.01 706.25 642.99 706.25L592.12 695.59C592 695.57 591.91 695.48 591.86 695.37L560.98 618.55C560.94 618.46 560.95 618.35 560.99 618.26C561.03 618.17 561.12 618.1 561.22 618.07C569.78 615.71 577.13 609.24 580.39 601.19C580.46 601.01 580.67 600.92 580.85 600.99L659.48 631.32C659.65 631.38 659.74 631.56 659.7 631.73L643.4 705.97C643.36 706.14 643.21 706.25 643.05 706.25H643.06ZM592.45 694.91L642.79 705.46L658.94 631.87L580.92 601.78C577.56 609.76 570.28 616.18 561.79 618.65L592.45 694.92V694.91Z"
        fill="white"
      />
      <path
        d="M592.19 695.58C592.19 695.58 592.14 695.58 592.12 695.58L501.66 676.51C501.57 676.49 501.48 676.43 501.43 676.35C501.38 676.27 501.36 676.17 501.38 676.08L515.48 610.81C515.52 610.62 515.7 610.49 515.9 610.53C520 611.32 524.65 612.24 529.34 613.17C533.98 614.09 538.66 615.01 542.85 615.82C543.7 615.99 544.59 616.23 545.65 616.6C550.99 618.47 556.53 618.98 561.25 618.05C561.42 618.02 561.59 618.11 561.65 618.27L592.53 695.09C592.58 695.21 592.56 695.35 592.47 695.46C592.4 695.54 592.3 695.59 592.19 695.59V695.58ZM502.16 675.87L591.61 694.73L561.1 618.81C556.32 619.68 550.77 619.14 545.41 617.27C544.39 616.91 543.53 616.67 542.71 616.51C538.51 615.7 533.84 614.78 529.2 613.86C524.65 612.96 520.12 612.07 516.11 611.29L502.16 675.86V675.87Z"
        fill="white"
      />
      <path
        d="M455.93 665.83C455.93 665.83 455.88 665.83 455.85 665.83L411.32 655.85C411.13 655.81 411.01 655.62 411.05 655.43L424.35 590.77C424.37 590.68 424.43 590.59 424.51 590.54C424.59 590.49 424.69 590.47 424.79 590.49L470.47 600.96C470.66 601 470.78 601.2 470.74 601.39L456.29 665.56C456.25 665.73 456.1 665.84 455.94 665.84L455.93 665.83ZM411.82 655.21L455.66 665.04L469.95 601.58L424.98 591.27L411.83 655.22L411.82 655.21Z"
        fill="white"
      />
      <path
        d="M411.4 655.84C411.4 655.84 411.35 655.84 411.33 655.84L364.71 646.52C364.62 646.5 364.53 646.45 364.48 646.36C364.43 646.28 364.41 646.18 364.43 646.09L376.9 588.23C377.47 585.76 379.68 583.8 382.8 582.97C383.53 582.78 384.36 582.68 385.39 582.66C386.13 582.66 386.88 582.72 387.6 582.87L424.77 590.49C424.86 590.51 424.95 590.56 425 590.64C425.05 590.72 425.07 590.82 425.05 590.91L411.75 655.57C411.71 655.74 411.57 655.86 411.4 655.86V655.84ZM365.2 645.88L411.11 655.06L424.26 591.11L387.44 583.57C386.77 583.43 386.08 583.37 385.39 583.38C384.42 583.4 383.65 583.49 382.98 583.66C380.77 584.24 378.2 585.79 377.6 588.38L365.2 645.88Z"
        fill="white"
      />
      <path
        d="M396.93 722.26C396.93 722.26 396.88 722.26 396.86 722.26C389.39 720.73 382.97 719.58 377.82 718.66C364 716.19 357.06 714.95 354.36 710.86C351.7 706.82 353.24 700.2 356.3 687.01C358.53 677.42 361.59 664.27 364.42 646.12C364.44 646.02 364.49 645.94 364.57 645.88C364.65 645.82 364.75 645.8 364.85 645.82L411.47 655.14C411.56 655.16 411.65 655.21 411.7 655.3C411.75 655.39 411.77 655.48 411.75 655.57L397.28 721.99C397.24 722.16 397.09 722.27 396.93 722.27V722.26ZM365.07 646.59C362.25 664.57 359.21 677.62 356.99 687.16C353.97 700.15 352.45 706.67 354.95 710.45C357.48 714.29 364.32 715.51 377.94 717.94C383.02 718.85 389.33 719.97 396.65 721.47L410.97 655.76L365.07 646.59Z"
        fill="white"
      />
      <path
        d="M501.73 676.51C501.73 676.51 501.68 676.51 501.65 676.51L455.85 665.83C455.66 665.79 455.54 665.59 455.58 665.4L470.03 601.23C470.07 601.04 470.26 600.92 470.46 600.96L515.92 610.54C516.01 610.56 516.1 610.62 516.15 610.7C516.2 610.78 516.22 610.88 516.2 610.97L502.1 676.24C502.08 676.33 502.02 676.42 501.94 676.47C501.88 676.51 501.82 676.53 501.75 676.53L501.73 676.51ZM456.36 665.2L501.46 675.72L515.4 611.16L470.65 601.73L456.36 665.2Z"
        fill="white"
      />
      <path
        d="M441.81 731.88C441.81 731.88 441.76 731.88 441.73 731.88L396.84 722.26C396.75 722.24 396.66 722.18 396.61 722.1C396.56 722.02 396.54 721.92 396.56 721.83L411.03 655.41C411.05 655.32 411.11 655.23 411.19 655.18C411.27 655.13 411.37 655.11 411.46 655.13L455.99 665.11C456.18 665.15 456.3 665.34 456.26 665.54L442.14 731.59C442.12 731.68 442.06 731.76 441.98 731.82C441.92 731.86 441.85 731.88 441.78 731.88H441.81ZM397.35 721.62L441.53 731.09L455.5 665.74L411.67 655.91L397.35 721.62Z"
        fill="white"
      />
      <path
        d="M486.76 741.58C486.76 741.58 486.71 741.58 486.68 741.58L441.73 731.87C441.54 731.83 441.41 731.64 441.45 731.44L455.57 665.39C455.59 665.3 455.65 665.21 455.73 665.16C455.81 665.11 455.91 665.09 456.01 665.11L501.81 675.79C502 675.83 502.12 676.03 502.08 676.22L487.11 741.29C487.07 741.46 486.92 741.57 486.76 741.57V741.58ZM442.24 731.24L486.49 740.8L501.3 676.42L456.2 665.9L442.24 731.24Z"
        fill="white"
      />
      <path
        d="M532.06 751.13C532.06 751.13 532.01 751.13 531.99 751.13L486.69 741.58C486.6 741.56 486.51 741.5 486.46 741.42C486.41 741.34 486.39 741.24 486.41 741.15L501.38 676.08C501.42 675.89 501.6 675.77 501.8 675.81L546.56 685.19C546.65 685.21 546.74 685.27 546.79 685.35C546.84 685.43 546.86 685.53 546.84 685.62L532.41 750.86C532.37 751.03 532.22 751.14 532.06 751.14V751.13ZM487.19 740.95L531.79 750.35L546.06 685.81L502 676.58L487.19 740.95Z"
        fill="white"
      />
      <path
        d="M577.82 760.8C577.82 760.8 577.77 760.8 577.75 760.8L531.99 751.14C531.9 751.12 531.81 751.06 531.76 750.98C531.71 750.9 531.69 750.8 531.71 750.71L546.14 685.47C546.18 685.28 546.38 685.16 546.57 685.2L592.27 694.89C592.36 694.91 592.45 694.97 592.5 695.05C592.55 695.13 592.57 695.23 592.55 695.32L578.18 760.53C578.14 760.7 577.99 760.81 577.83 760.81L577.82 760.8ZM532.49 750.49L577.54 760.01L591.75 695.5L546.76 685.95L532.49 750.49Z"
        fill="white"
      />
      <path
        d="M628.23 772.31C628.23 772.31 628.18 772.31 628.15 772.31L577.74 760.8C577.55 760.76 577.43 760.56 577.47 760.37L591.84 695.16C591.88 694.97 592.07 694.85 592.27 694.88L643.14 705.54C643.23 705.56 643.32 705.62 643.37 705.7C643.42 705.78 643.44 705.88 643.42 705.97L628.58 772.03C628.56 772.12 628.5 772.2 628.42 772.26C628.36 772.3 628.3 772.32 628.23 772.32V772.31ZM578.25 760.16L627.96 771.51L642.64 706.16L592.47 695.65L578.26 760.17L578.25 760.16Z"
        fill="white"
      />
      <path
        d="M325.2 706.4C320.15 706.4 312.8 704.82 301.53 702.4C297.32 701.49 292.55 700.47 287.16 699.37C287.07 699.35 286.98 699.29 286.93 699.21C286.88 699.13 286.86 699.03 286.88 698.94L301.29 632.74C301.33 632.55 301.53 632.43 301.72 632.46L347.18 642.04C347.28 642.06 347.36 642.12 347.41 642.2C347.46 642.28 347.48 642.38 347.45 642.48C343.01 659.83 340.85 672.88 339.27 682.42C337.12 695.39 336.05 701.9 331.85 704.75C330.17 705.89 328.04 706.4 325.19 706.4H325.2ZM287.66 698.74C292.9 699.81 297.56 700.81 301.68 701.7C319.1 705.44 327.05 707.15 331.45 704.16C335.39 701.48 336.51 694.67 338.56 682.31C340.13 672.83 342.27 659.87 346.66 642.68L301.92 633.25L287.66 698.75V698.74Z"
        fill="white"
      />
      <path
        d="M435.8 574.33C435.8 574.33 435.75 574.33 435.72 574.33L389.98 563.77C389.79 563.73 389.67 563.54 389.71 563.34L409.12 473.32C409.16 473.13 409.35 473.01 409.55 473.04L454.88 482.53C455.07 482.57 455.2 482.76 455.16 482.96L436.16 574.05C436.14 574.14 436.08 574.23 436 574.28C435.94 574.32 435.87 574.34 435.81 574.34L435.8 574.33ZM390.49 563.14L435.53 573.54L454.38 483.16L409.75 473.82L390.49 563.14Z"
        fill="white"
      />
      <path
        d="M480.35 583.77C480.35 583.77 480.3 583.77 480.28 583.77L435.73 574.33C435.54 574.29 435.41 574.1 435.45 573.9L454.45 482.81C454.47 482.72 454.53 482.63 454.61 482.58C454.69 482.53 454.79 482.51 454.89 482.53L500.42 493.16C500.61 493.2 500.73 493.4 500.69 493.59L480.71 583.49C480.67 583.66 480.52 583.77 480.36 583.77H480.35ZM436.23 573.69L480.08 582.98L499.91 493.77L455.08 483.31L436.23 573.69Z"
        fill="white"
      />
      <path
        d="M553.24 599.81C548.48 599.81 541.79 598.09 531.53 595.41C519.75 592.33 503.63 588.11 480.3 583.77C480.2 583.75 480.12 583.7 480.07 583.62C480.02 583.54 480 583.44 480.02 583.34L489.68 539.1C489.72 538.91 489.9 538.79 490.1 538.82L577.44 555.94C577.54 555.96 577.64 556.03 577.69 556.12C577.74 556.21 577.75 556.33 577.71 556.42C574.39 564.86 571.94 571.87 569.97 577.5C564.21 593.94 562.04 599.79 553.26 599.79L553.24 599.81ZM480.78 583.13C503.94 587.46 519.98 591.65 531.7 594.71C550.913 599.737 563.433 593.927 569.26 577.28C571.2 571.73 573.61 564.85 576.85 556.57L490.28 539.6L480.78 583.13Z"
        fill="white"
      />
      <path
        d="M577.35 556.66C577.35 556.66 577.3 556.66 577.28 556.66L489.94 539.54C489.84 539.52 489.76 539.46 489.71 539.38C489.66 539.3 489.64 539.2 489.66 539.1L499.98 493.43C500.02 493.24 500.21 493.12 500.41 493.16L587.08 511.33C587.17 511.35 587.26 511.41 587.31 511.49C587.36 511.57 587.38 511.67 587.36 511.76L577.71 556.38C577.67 556.55 577.52 556.66 577.36 556.66H577.35ZM490.44 538.89L577.07 555.87L586.57 511.95L500.61 493.92L490.44 538.88V538.89Z"
        fill="white"
      />
      <path
        d="M587 512.04C587 512.04 586.95 512.04 586.93 512.04L511.61 496.22C511.47 496.19 511.36 496.08 511.33 495.95L501.63 453.39C501.61 453.29 501.63 453.18 501.69 453.09C501.75 453.01 501.85 452.95 501.96 452.95L589.35 448.52C589.54 448.52 589.66 448.62 589.71 448.77C590.14 450.17 590.6 451.64 591.07 453.15C593.01 459.32 595.2 466.32 596.27 472.23C596.27 472.28 596.27 472.32 596.27 472.37L595.74 474.87C593.5 485.37 589.76 502.95 587.35 511.77C587.31 511.93 587.16 512.04 587 512.04ZM511.98 495.56L586.74 511.26C589.15 502.35 592.83 485.09 595.04 474.72L595.56 472.29C594.5 466.44 592.32 459.5 590.4 453.37C589.95 451.95 589.52 450.57 589.12 449.25L502.44 453.64L511.99 495.56H511.98Z"
        fill="white"
      />
      <path
        d="M501.99 453.67C501.82 453.67 501.68 453.56 501.64 453.39L491.71 411.39C491.67 411.2 491.78 411.01 491.96 410.96L572.97 387.97C573.06 387.94 573.16 387.96 573.25 388C573.33 388.05 573.39 388.13 573.42 388.22L589.73 448.78C589.76 448.89 589.73 449 589.67 449.09C589.61 449.18 589.51 449.23 589.4 449.24L502.01 453.67C502.01 453.67 502 453.67 501.99 453.67ZM492.48 411.56L502.26 452.93L588.91 448.54L572.81 388.76L492.48 411.56Z"
        fill="white"
      />
      <path
        d="M492.06 411.67C492 411.67 491.93 411.65 491.88 411.62C491.8 411.57 491.73 411.49 491.71 411.39L482.62 370.97C482.58 370.81 482.66 370.65 482.8 370.57L551.45 334.54C551.62 334.45 551.84 334.51 551.93 334.69C555.76 341.85 563.55 356.35 567.41 363.52C568.83 368.12 571.72 380.82 573.11 386.93L573.41 388.24C573.45 388.43 573.34 388.61 573.16 388.67L492.15 411.66C492.15 411.66 492.08 411.67 492.05 411.67H492.06ZM483.37 371.08L492.32 410.86L572.64 388.06L572.42 387.08C571.03 380.98 568.15 368.29 566.76 363.79C562.98 356.78 555.36 342.6 551.47 335.34L483.38 371.08H483.37Z"
        fill="white"
      />
      <path
        d="M482.96 371.25C482.85 371.25 482.75 371.2 482.68 371.12L453.58 334.85C453.46 334.7 453.48 334.48 453.63 334.35L504.66 290.51C506.29 289.11 508.31 288.31 510.36 288.24C511.35 288.21 512.18 288.19 512.93 288.27C517.5 288.76 527.18 297.09 533.59 302.6C536.22 304.86 538.3 306.65 539.05 307.05C539.12 307.09 539.17 307.15 539.21 307.22L551.94 334.71C552.02 334.88 551.94 335.09 551.78 335.18L483.13 371.21C483.08 371.24 483.02 371.25 482.96 371.25ZM454.36 334.66L483.06 370.42L551.15 334.69L538.61 307.61C537.73 307.09 535.78 305.42 533.13 303.13C527.17 298.01 517.2 289.44 512.86 288.97C512.15 288.89 511.35 288.91 510.38 288.94C508.5 289 506.63 289.74 505.13 291.03L454.36 334.65V334.66Z"
        fill="white"
      />
      <path
        d="M393.62 398.42C393.53 398.42 393.44 398.39 393.38 398.33L317.8 329.67C317.72 329.6 317.68 329.49 317.68 329.38C317.68 329.27 317.74 329.17 317.83 329.11L357.74 300.88C357.89 300.77 358.1 300.8 358.22 300.94C361.56 304.9 367.19 307.01 371.13 308.24C373.09 308.85 376 309.3 378.07 309.53C378.16 309.53 378.24 309.58 378.3 309.65C379.07 310.54 382.13 313.6 386.36 317.84C405.22 336.75 423.61 355.45 423.32 358.4C423.32 358.46 423.29 358.53 423.25 358.58L393.9 398.29C393.84 398.37 393.75 398.42 393.65 398.43C393.64 398.43 393.63 398.43 393.61 398.43L393.62 398.42ZM318.61 329.43L393.56 397.52L422.61 358.23C422.42 354.99 397.77 330.27 385.86 318.34C381.78 314.25 378.78 311.25 377.87 310.23C375.77 310 372.89 309.54 370.93 308.93C366.98 307.69 361.38 305.59 357.9 301.65L318.62 329.43H318.61Z"
        fill="white"
      />
      <path
        d="M318.04 329.76C317.96 329.76 317.87 329.73 317.8 329.67L279.62 296.21C279.62 296.21 279.54 296.12 279.52 296.07L231.2 169.13C231.16 169.03 231.17 168.92 231.22 168.82C231.27 168.73 231.37 168.66 231.47 168.64L258.79 163.98C258.91 163.96 259.04 164 259.12 164.1L361.16 278.79C361.29 278.93 361.28 279.15 361.14 279.28C360.84 279.58 360.39 279.96 359.91 280.37C359.15 281.01 358.3 281.74 357.98 282.19C354.08 287.73 354.98 290.6 356.23 294.58L356.28 294.75C356.86 296.6 358.28 301.05 358.28 301.05C358.33 301.2 358.28 301.36 358.15 301.45L318.24 329.68C318.18 329.72 318.1 329.75 318.03 329.75L318.04 329.76ZM280.16 295.73L318.06 328.94L357.53 301.03C357.22 300.06 356.11 296.56 355.61 294.98L355.56 294.81C354.3 290.79 353.3 287.62 357.41 281.79C357.78 281.26 358.64 280.54 359.47 279.84C359.82 279.55 360.15 279.27 360.41 279.03L258.73 164.75L232.04 169.3L280.17 295.74L280.16 295.73Z"
        fill="white"
      />
      <path
        d="M360.9 279.4C360.8 279.4 360.7 279.36 360.63 279.28L258.59 164.59C258.5 164.49 258.47 164.35 258.52 164.22C258.57 164.1 258.68 164.01 258.81 163.99L320.89 156.78C321.02 156.76 321.15 156.82 321.23 156.93L394.08 262C394.17 262.13 394.16 262.3 394.07 262.43L381.89 278.07C381.78 278.21 381.59 278.25 381.44 278.17L380.92 277.9C379.58 277.19 378.32 276.51 374.92 276.08C370.49 275.52 367.09 276.03 363.53 277.78C362.42 278.33 361.12 279.32 361.11 279.33C361.05 279.38 360.97 279.4 360.89 279.4H360.9ZM259.59 164.62L360.95 278.55C361.4 278.22 362.35 277.55 363.23 277.12C366.92 275.3 370.45 274.77 375.03 275.36C378.56 275.81 379.88 276.51 381.28 277.25L381.53 277.38L393.36 262.18L320.79 157.5L259.61 164.61L259.59 164.62Z"
        fill="white"
      />
      <path
        d="M231.54 169.36C231.42 169.36 231.3 169.3 231.23 169.19L207.91 130.28C207.87 130.22 207.86 130.15 207.86 130.07L212.68 43.07C212.68 42.97 212.73 42.88 212.8 42.82C212.87 42.76 212.97 42.72 213.06 42.73L260.7 46.33C260.89 46.34 261.04 46.5 261.03 46.69L259.22 164.33C259.22 164.5 259.09 164.65 258.92 164.68L231.6 169.34C231.6 169.34 231.56 169.34 231.54 169.34V169.36ZM208.58 130.01L231.72 168.61L258.5 164.04L260.31 47.04L213.37 43.49L208.58 130.01Z"
        fill="white"
      />
      <path
        d="M258.86 164.71C258.77 164.71 258.69 164.68 258.62 164.62C258.54 164.55 258.5 164.45 258.5 164.35L260.31 46.7101C260.31 46.5101 260.47 46.3601 260.67 46.3601H262.65L306.33 56.0301C306.48 56.0601 306.59 56.1801 306.61 56.3301L321.3 157.09C321.31 157.19 321.29 157.28 321.23 157.36C321.17 157.44 321.08 157.49 320.99 157.5L258.91 164.71C258.91 164.71 258.88 164.71 258.87 164.71H258.86ZM261.03 47.0601L259.23 163.94L320.54 156.82L305.94 56.6701L262.59 47.0601H261.04H261.03Z"
        fill="white"
      />
      <path
        d="M320.95 157.49C320.95 157.49 320.85 157.49 320.81 157.46C320.69 157.41 320.61 157.3 320.6 157.18L305.91 56.42C305.89 56.29 305.94 56.16 306.05 56.08C306.15 56 306.29 55.98 306.41 56.04C307.71 56.6 317.14 58.78 328.06 61.3C348.89 66.11 374.81 72.09 377.82 74.29C379.28 75.36 380.12 76.89 380.07 78.38C380.13 79.02 379.71 81.53 377.37 93.35C376.99 95.3 376.65 96.98 376.63 97.14L376.59 97.31C376.42 98.09 376.16 99.26 376.54 99.92C377 100.52 379.65 103.95 382 106.98C383.6 109.05 385.03 110.89 385.43 111.42C385.49 111.5 385.51 111.6 385.5 111.69C385.49 111.79 385.43 111.87 385.35 111.93L321.17 157.43C321.11 157.47 321.04 157.5 320.96 157.5L320.95 157.49ZM306.7 56.91L321.22 156.5L384.62 111.55C384.04 110.8 382.79 109.19 381.42 107.41C379.07 104.38 376.42 100.95 375.93 100.31C375.39 99.37 375.67 98.09 375.88 97.15L375.91 97.02C375.92 96.88 376.16 95.68 376.65 93.21C377.53 88.76 379.34 79.59 379.36 78.54C379.34 78.49 379.33 78.44 379.33 78.38C379.39 77.11 378.66 75.8 377.38 74.86C374.49 72.75 347.56 66.53 327.89 61.99C317.56 59.61 309.2 57.68 306.7 56.9V56.91Z"
        fill="white"
      />
      <path
        d="M349.9 199.46C349.9 199.46 349.86 199.46 349.84 199.46C349.75 199.44 349.66 199.39 349.61 199.31L320.66 157.34C320.55 157.18 320.59 156.96 320.75 156.84L384.93 111.34C385.01 111.28 385.11 111.26 385.2 111.28C385.3 111.3 385.38 111.35 385.43 111.43L414.01 154.37C414.12 154.53 414.08 154.75 413.92 154.86L350.11 199.4C350.05 199.44 349.98 199.46 349.9 199.46ZM321.45 157.22L349.99 198.6L413.21 154.47L385.03 112.14L321.44 157.22H321.45Z"
        fill="white"
      />
      <path
        d="M378.91 241.19C378.8 241.19 378.68 241.14 378.61 241.04L349.6 199.31C349.49 199.15 349.53 198.92 349.69 198.81L413.5 154.27C413.66 154.16 413.89 154.2 414 154.36L443.43 196.02C443.49 196.1 443.51 196.2 443.49 196.29C443.47 196.38 443.42 196.47 443.34 196.52L379.11 241.12C379.05 241.16 378.98 241.18 378.9 241.18L378.91 241.19ZM350.4 199.19L379 240.33L442.63 196.14L413.62 155.07L350.4 199.2V199.19Z"
        fill="white"
      />
      <path
        d="M391.24 296.63C391.18 296.63 391.12 296.62 391.07 296.59C390.94 296.52 390.87 296.39 390.88 296.24C391.23 291.66 385.57 279.91 381.48 278.18C381.38 278.14 381.3 278.05 381.27 277.94C381.24 277.83 381.27 277.72 381.33 277.63L393.34 262.2L378.6 241.05C378.55 240.97 378.52 240.87 378.54 240.78C378.56 240.69 378.61 240.6 378.69 240.55L442.92 195.95C443 195.9 443.1 195.87 443.19 195.89C443.29 195.91 443.37 195.97 443.42 196.05L468.87 234.99C468.97 235.15 468.94 235.35 468.79 235.47L391.44 296.57C391.38 296.62 391.3 296.65 391.22 296.65L391.24 296.63ZM382.17 277.72C386.28 280 391.39 290.42 391.6 295.52L468.1 235.09L443.03 196.74L379.4 240.93L394.08 262C394.17 262.13 394.17 262.3 394.07 262.43L382.17 277.72Z"
        fill="white"
      />
      <path
        d="M428.52 329.18C427.46 329.18 426.4 329.1 425.39 328.95C423.97 328.74 422.57 328.24 421.23 327.48C420.59 327.12 420 326.66 419.49 326.13L390.98 296.51C390.91 296.44 390.87 296.34 390.88 296.24C390.88 296.14 390.94 296.04 391.02 295.98L468.37 234.88C468.45 234.82 468.55 234.79 468.64 234.81C468.74 234.82 468.83 234.88 468.88 234.96L492.04 267.54C493.44 269.36 493.67 271.73 492.74 274.79C492.1 276.91 490.82 278.82 489.05 280.33L433.21 327.85C432.22 328.64 431.45 329.01 430.72 329.08C429.99 329.15 429.25 329.19 428.52 329.19V329.18ZM391.77 296.3L420.01 325.63C420.47 326.11 421.01 326.52 421.59 326.86C422.85 327.58 424.17 328.04 425.5 328.24C427.16 328.49 428.94 328.53 430.66 328.35C431.25 328.3 431.9 327.97 432.76 327.28L488.59 279.76C490.26 278.34 491.46 276.54 492.05 274.56C492.9 271.74 492.71 269.58 491.46 267.96C491.46 267.96 491.46 267.96 491.46 267.95L468.51 235.68L391.77 296.3Z"
        fill="white"
      />
      <path
        d="M287.23 699.38C287.23 699.38 287.18 699.38 287.16 699.38L234.04 688.18C233.94 688.16 233.85 688.09 233.8 688L232.46 685.63L230.91 681.35L227.8 674.68L220.9 665.74L211.01 658.86L197.94 648.33C197.8 648.22 197.77 648.02 197.86 647.87L235.3 584.79C235.35 584.7 235.44 584.64 235.54 584.62C235.64 584.6 235.74 584.62 235.82 584.68L301.86 632.53C301.98 632.61 302.03 632.76 302 632.9L287.59 699.1C287.55 699.27 287.4 699.38 287.24 699.38H287.23ZM234.34 687.49L286.96 698.59L301.25 632.97L235.72 585.49L198.64 647.96L211.44 658.28L221.36 665.18C221.36 665.18 221.42 665.23 221.44 665.26L228.41 674.31L231.57 681.08L233.11 685.33L234.34 687.5V687.49Z"
        fill="white"
      />
      <path
        d="M198.17 648.4C198.09 648.4 198.01 648.37 197.94 648.32L189.19 641.21L181.16 636.64L173.11 632.23L170.22 630.5C170.16 630.47 170.12 630.42 170.09 630.36L158.58 608.68L155.33 603.13L151.34 598.38L144.71 592.64C144.57 592.52 144.55 592.32 144.64 592.17L181.24 535.33C181.3 535.24 181.39 535.18 181.5 535.17C181.61 535.16 181.71 535.19 181.79 535.26L235.87 584.7C236 584.82 236.02 585 235.94 585.15L198.5 648.23C198.45 648.32 198.36 648.38 198.25 648.4C198.23 648.4 198.21 648.4 198.19 648.4H198.17ZM170.67 629.93L173.46 631.6L181.51 636.01L189.6 640.62L198.08 647.5L235.16 585.03L181.61 536.08L145.41 592.3L151.84 597.88L155.91 602.72L159.21 608.33L170.68 629.94L170.67 629.93Z"
        fill="white"
      />
      <path
        d="M144.93 592.72C144.93 592.72 144.86 592.72 144.83 592.71L99.4998 579.69C99.3898 579.66 99.2998 579.58 99.2598 579.47C99.2198 579.36 99.2298 579.25 99.2898 579.15L143.42 509.51C143.45 509.46 143.5 509.41 143.56 509.38L222.51 468.25C222.66 468.18 222.83 468.21 222.94 468.33C223.05 468.45 223.07 468.63 222.98 468.77L145.23 592.56C145.16 592.67 145.05 592.73 144.93 592.73V592.72ZM100.16 579.13L144.76 591.94L221.66 469.5L143.97 509.97L100.15 579.13H100.16Z"
        fill="white"
      />
      <path
        d="M99.6002 579.7C99.6002 579.7 99.5002 579.69 99.4502 579.67L79.1102 570.65C78.9302 570.57 78.8502 570.37 78.9202 570.19L89.0902 544.68L160.83 431.59C160.94 431.42 161.15 431.37 161.32 431.47L222.86 468.26C222.97 468.33 223.04 468.45 223.04 468.58C223.04 468.71 222.96 468.83 222.85 468.89L143.99 509.97L99.9102 579.53C99.8402 579.64 99.7302 579.7 99.6102 579.7H99.6002ZM79.7302 570.13L99.4702 578.88L143.43 509.5C143.46 509.45 143.51 509.4 143.57 509.37L221.95 468.54L161.27 432.26L89.7502 544.99L79.7402 570.12L79.7302 570.13Z"
        fill="white"
      />
      <path
        d="M89.43 545.17C89.37 545.17 89.31 545.16 89.26 545.13L65.59 532.41C65.5 532.36 65.44 532.28 65.41 532.18C65.39 532.08 65.41 531.98 65.46 531.89L89.85 494.98L97.8 452.19L65.71 415.11C65.64 415.03 65.61 414.93 65.63 414.82C65.65 414.71 65.7 414.63 65.79 414.57L115.59 382.32C115.69 382.26 115.82 382.24 115.93 382.29C116.04 382.34 116.12 382.44 116.14 382.56L121.05 409.02L161.32 431.47C161.41 431.52 161.47 431.6 161.49 431.7C161.51 431.8 161.49 431.9 161.45 431.98L89.74 545.01C89.67 545.12 89.55 545.18 89.44 545.18L89.43 545.17ZM66.28 531.96L89.3 544.33L160.62 431.9L120.54 409.55C120.45 409.5 120.38 409.41 120.36 409.3L115.52 383.2L66.52 414.94L98.46 451.84C98.53 451.92 98.56 452.03 98.54 452.14L90.54 495.17C90.54 495.17 90.51 495.26 90.49 495.3L66.28 531.95V531.96Z"
        fill="white"
      />
      <path
        d="M50.44 548.84C50.37 548.84 50.29 548.82 50.23 548.77L31.88 535.38L14.88 515.56C14.82 515.49 14.79 515.41 14.79 515.33V507.58C14.79 507.48 14.83 507.38 14.91 507.31L47.32 478.2L61.14 444.24V409.68C61.14 409.53 61.23 409.39 61.37 409.34C61.51 409.28 61.67 409.33 61.77 409.44L98.47 451.85C98.54 451.93 98.57 452.04 98.55 452.15L90.55 495.18C90.55 495.18 90.52 495.27 90.5 495.31L69.33 527.36L50.71 548.71C50.64 548.79 50.54 548.83 50.44 548.83V548.84ZM15.5 515.19L32.35 534.85L50.37 547.99L68.74 526.93L89.85 494.98L97.8 452.19L61.84 410.65V444.31C61.84 444.31 61.84 444.4 61.81 444.45L47.93 478.55C47.91 478.6 47.88 478.65 47.84 478.68L15.49 507.73V515.19H15.5Z"
        fill="white"
      />
      <path
        d="M233.97 704.89C233.97 704.89 233.92 704.89 233.89 704.89L95.4899 675.05C95.2999 675.01 95.1699 674.82 95.2099 674.63L110.92 599.54C110.94 599.44 111 599.35 111.09 599.3C111.18 599.25 111.29 599.24 111.39 599.27L140.41 609.29C140.47 609.31 140.52 609.34 140.56 609.39L148.72 618.54L163.65 643.32L185.52 656.67L213.99 678.47L220.03 685.22L225.39 692.36L234.24 704.34C234.33 704.46 234.33 704.62 234.25 704.75C234.18 704.85 234.07 704.92 233.95 704.92L233.97 704.89ZM95.9999 674.41L233.11 703.98L224.82 692.77L219.49 685.66L213.52 678.98L185.13 657.24L163.21 643.87C163.21 643.87 163.12 643.8 163.09 643.75L148.15 618.95L140.09 609.93L111.54 600.07L95.9899 674.41H95.9999Z"
        fill="white"
      />
    </svg>

    // <svg
    //   width="932"
    //   height="947"
    //   viewBox="0 0 932 947"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M711.14 400.4L806.36 367.01C831.19 407.98 801.21 390.44 807.37 421.51L729.28 443.169C728.07 432.279 716.54 409.9 711.15 400.4H711.14Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M741.43 487.719L729.27 443.18L807.36 421.52C808.1 435.42 811.47 437.51 817.99 445.7C819.98 448.2 821.99 450.689 824.17 453.039C826.93 456.019 829.46 459.68 830.55 463.14L741.43 487.729V487.719Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M753.42 532.72L741.43 487.72L830.55 463.13C830.74 463.65 830.96 464.21 831.21 464.79C832.92 468.86 833.33 473.32 832.55 477.66C831.77 482 831.29 486.46 831.37 490.45C831.42 493.06 831.46 493.82 832.65 495.41C836.03 499.55 840.07 505.86 841.54 511.08L835.93 533.73L753.43 532.71L753.42 532.72Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M747.95 578.229L755.58 540.84L753.42 532.719L835.91 533.729L823.4 594.96L747.95 578.229Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M736.52 627.94L747.95 578.229L823.4 594.96L811.85 644.49L736.52 627.94Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M720.66 671.159L736.52 627.939L811.85 644.489L799.3 701.489L720.66 671.159Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M701.25 688.249C709.79 685.899 717.32 679.389 720.66 671.159L799.3 701.489L783 775.729L732.13 765.069L701.25 688.249Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M655.77 680.729C663.93 682.299 674.29 684.39 682.72 686.02C683.66 686.2 684.57 686.469 685.47 686.789C690.46 688.529 696.08 689.28 701.26 688.26L732.14 765.08L641.68 746.01L655.78 680.74L655.77 680.729Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M551.33 725.33L564.63 660.67L610.31 671.15L595.86 735.31L551.33 725.33Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M517.18 658.149C517.79 655.529 520.27 653.829 522.82 653.159C523.63 652.939 524.48 652.879 525.32 652.859C526.04 652.849 526.75 652.919 527.45 653.059L564.62 660.679L551.32 725.339L504.7 716.019L517.17 658.159L517.18 658.149Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M536.86 791.75C476.55 779.38 492.6 793.51 504.71 716.02L551.33 725.34L536.86 791.76V791.75Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M595.86 735.309L610.31 671.149L655.77 680.729L641.67 745.999L595.86 735.309Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M581.75 801.36L595.86 735.31L551.33 725.33L536.86 791.75L581.75 801.36Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M626.7 811.07L641.67 746L595.86 735.31L581.75 801.36L626.7 811.07Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M672 820.62L626.7 811.07L641.67 746L686.43 755.37L672 820.62Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M717.76 830.279L672 820.619L686.43 755.369L732.13 765.069L717.76 830.279Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M768.17 841.789L717.76 830.279L732.13 765.069L783 775.729L768.17 841.789Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M487.04 712.24C468.62 784.27 489.53 781.59 427.17 768.87L441.58 702.67L487.04 712.25V712.24Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M549.41 543.239L594.74 552.729L575.74 643.819L530 633.249L549.41 543.239Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M640.27 563.349L620.29 653.26L575.74 643.82L594.74 552.729L640.27 563.349Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M717.29 626.14C693.33 687.13 715.25 670.94 620.29 653.25L629.95 609.01L717.29 626.13V626.14Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M726.94 581.53L717.29 626.14L629.95 609.02L640.27 563.35L726.94 581.53Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M641.92 523.15L729.31 518.719C731.49 525.849 734.54 534.81 735.86 542.14C733.76 551.97 729.55 571.96 726.94 581.52L651.62 565.7L641.92 523.14V523.15Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M631.99 481.149L713 458.159L729.31 518.719L641.92 523.149L631.99 481.149Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M622.9 440.729L691.55 404.699C695.38 411.859 703.17 426.359 707.03 433.529C708.54 438.409 711.82 452.979 713 458.159L631.99 481.149L622.89 440.729H622.9Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M593.79 404.459L622.89 440.729L691.54 404.699L678.81 377.209C676.18 375.809 659.16 359.149 652.82 358.479C652.06 358.399 651.18 358.419 650.3 358.449C648.28 358.509 646.36 359.309 644.83 360.629C635.75 368.429 593.8 404.469 593.8 404.469L593.79 404.459Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M562.9 428.2L533.55 467.91L457.97 399.25L497.88 371.02C501.08 374.81 506.35 376.99 510.96 378.44C512.95 379.06 515.92 379.52 517.97 379.74C521.19 383.42 563.29 424.22 562.9 428.21V428.2Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M371.48 238.85L398.8 234.19L500.84 348.88C500.07 349.65 498.19 351.05 497.63 351.83C493.57 357.59 494.59 360.57 495.88 364.7C496.46 366.55 497.88 371 497.88 371L457.97 399.23L419.79 365.77L371.47 238.83L371.48 238.85Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M460.88 226.979L533.73 332.049L521.55 347.689C519.96 346.869 518.8 346.059 514.91 345.559C510.43 344.989 506.98 345.489 503.32 347.289C502.16 347.859 500.84 348.879 500.84 348.879L398.8 234.189L460.88 226.979V226.979Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M352.97 112.949L400.61 116.549L398.8 234.189L371.48 238.849L348.16 199.939L352.97 112.949Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M446.19 126.22L460.88 226.98L398.8 234.19L400.61 116.55H402.59L446.19 126.22Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M525.07 181.48L460.89 226.98L446.2 126.22C449.87 127.81 512.92 141.05 517.53 144.42C518.77 145.33 519.7 146.71 519.63 148.24C520.01 148.08 516.27 166.33 516.2 166.94C516.01 167.8 515.68 169.12 516.16 169.94C516.97 171.02 524.09 180.19 525.07 181.47V181.48Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M553.65 224.409L525.07 181.479L460.88 226.979L489.84 268.949L553.65 224.409Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M583.07 266.079L553.65 224.409L489.84 268.949L518.84 310.679L583.07 266.079Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M608.53 305.01L531.18 366.11C531.53 361.46 525.92 349.54 521.56 347.69L533.74 332.05L518.85 310.68L583.08 266.08L608.53 305.02V305.01Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M631.69 337.59C633.24 339.6 633.06 342.13 632.34 344.52C631.7 346.62 630.43 348.47 628.76 349.89L572.92 397.41C572.06 398.09 571.33 398.49 570.63 398.56C568.97 398.73 567.13 398.71 565.38 398.45C563.96 398.24 562.6 397.74 561.34 397.03C560.73 396.68 560.17 396.25 559.68 395.74L531.17 366.12L608.52 305.02L631.68 337.6L631.69 337.59Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M338.1 717.89L351.15 728.4L361.09 735.31L368.06 744.36L371.18 751.07L372.71 755.29L374.05 757.66L427.17 768.86L441.58 702.66L375.54 654.81L338.1 717.89Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M284.87 662.209L321.46 605.369L375.54 654.809L338.1 717.889L329.35 710.769L321.27 706.169L313.21 701.759L310.34 700.029L303.62 687.369L298.82 678.349L295.56 672.789L291.53 667.989L284.87 662.209Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M239.54 649.189L283.67 579.539L362.62 538.409L284.87 662.209L239.54 649.189Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M219.2 640.17L229.37 614.66L301.08 501.63L362.62 538.41L283.67 579.54L239.54 649.19L219.2 640.17Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M201.43 479.53L178.86 450.53L169.5 409.26L232.1 367.88L247.99 410.82L260.66 479.1L301.08 501.63L229.37 614.66L205.7 601.94L230.13 564.97L238.13 521.93L201.43 479.53Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M190.37 618.319L208.96 597.009L230.13 564.969L238.13 521.929L201.43 479.529V514.149L187.55 548.249L155.08 577.419V585.169L172.02 604.929L190.37 618.319Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   {/* <path
    //     d="M84.9102 329.59L132.32 347.62L143.7 339.78L146.6 259.25L238.13 175.9L203.69 137.5L100.79 232.73L84.9102 329.59Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   /> */}
    //   {/* <path
    //     d="M37.0601 288.76L84.9101 329.59L100.79 232.73L203.69 137.5L184.28 128.07L68.3101 109.35L37.0601 288.76Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   /> */}
    //   <path
    //     d="M251.21 669.449L235.51 744.529L373.9 774.379L365.05 762.399L359.71 755.289L353.72 748.589L325.29 726.799L303.34 713.409L288.4 688.609L280.24 679.469L251.21 669.449Z"
    //     stroke="white"
    //     stroke-width="0.72"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M457.47 775.67L386.4 759.46C384.82 759.1 383.46 758.09 382.66 756.69L377.94 748.43C377.78 748.15 377.6 747.88 377.39 747.63L369.78 738.33C369.51 738 369.2 737.7 368.86 737.43L336.52 712.22C336.29 712.04 336.05 711.88 335.8 711.74L322.73 704.39C318.37 701.94 314.82 698.25 312.55 693.79L304.31 677.62C304.01 677.03 303.61 676.5 303.13 676.05L290.5 664.09C289.92 663.54 289.23 663.12 288.48 662.85L236.92 644.3C236.76 644.24 236.59 644.17 236.44 644.1L235.75 643.78C235.28 643.56 234.85 643.28 234.46 642.95L234.13 642.67C233.94 642.51 233.76 642.34 233.59 642.15L233.07 641.58L232.59 640.73L232.33 639.88L232.15 638.94L232.18 638.1C232.18 637.94 232.2 637.79 232.21 637.63L232.35 636.45L232.42 635.53L232.46 635.28C233.22 633.66 233.8 631.95 234.2 630.17C234.78 628.11 235.08 625.94 235.07 623.7C234.99 611.57 225.71 601.61 213.74 600.08C212.48 599.88 211.19 599.78 209.87 599.78C196.1 599.78 184.93 610.95 184.93 624.72C184.93 629.99 186.57 634.87 189.35 638.9L190.78 640.77C193.76 644.31 197.7 647 202.2 648.46L231.07 662.8C231.29 662.91 231.52 663 231.75 663.08L278.12 679.17C278.96 679.46 279.73 679.95 280.35 680.58L287.21 687.57C287.6 687.97 287.94 688.43 288.2 688.93L297.35 706.31C300.17 711.66 304.47 716.08 309.74 719.05L324.13 727.15C324.37 727.29 324.6 727.44 324.82 727.61L354.52 750.48C354.85 750.73 355.14 751.02 355.41 751.33L361.31 758.31C361.43 758.45 361.54 758.6 361.65 758.75L370.96 772.19C371.76 773.35 372.96 774.18 374.34 774.51L447.83 792.56C449.58 792.99 451.44 792.58 452.85 791.44L459.82 785.81C463.58 782.77 462.18 776.74 457.47 775.67Z"
    //     fill="#D0D5DD"
    //   />
    // </svg>
  );
};
