import React, { useState } from "react";
import { auth } from "../../services/firebase";
import { confirmPasswordReset } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  GET_CONTACT_BY_EMAIL,
  GET_CUSTOM_OBJECT_BY_VID,
  UPDATE_PASSWORD,
  headersKey,
} from "../../utils/config";
import strings from "../../utils/fr.json";
import { Col, Container, Row, Image, Form, Button } from "react-bootstrap";
import Spinner from "../spinner";
import { constant } from "../../utils/constant";
import { domaine_st_come, domian_pine_hill_logo } from "../../assets/images";

function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inpval, setInpval] = useState({
    password: "",
    confirmPassword: "",
  });
  var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
  const [searchParams] = useSearchParams();
  const [error, setError] = useState({});
  let oobCode = searchParams.get("oobCode");
  let continueUrl = searchParams.get("continueUrl");
  let email = continueUrl?.split("=")[continueUrl?.split("=")?.length - 1];

  const handleChange = (name, value) => {
    setInpval({ ...inpval, [name]: value });
    setError({ ...error, [name]: null });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const contactId = await axios.get(GET_CONTACT_BY_EMAIL(email), {
      headers: headersKey,
    });
    const customObjectId = await axios.get(
      GET_CUSTOM_OBJECT_BY_VID(contactId?.data?.vid),
      { headers: headersKey }
    );
    await axios
      .patch(
        UPDATE_PASSWORD(customObjectId.data?.results[0]?.id),
        {
          properties: {
            mot_de_passe: inpval?.confirmPassword,
          },
        },
        { headers: headersKey }
      )
      .then((res) => {
        navigate("/");
        setLoading(false);
      });
  };

  const handlesubmit = async () => {
    setLoading(true);
    try {
      if (oobCode) {
        await confirmPasswordReset(auth, oobCode, inpval?.confirmPassword)
          .then(() => {
            handleUpdate();
          })
          .catch((err) => {
            console.log("error", err.message);
          });
        setInpval({ password: "", confirmPassword: "" });
      } else {
        // console.log("missing oob Code");
      }
    } catch (error) {
      if (error.code === "auth/invalid-action-code") {
        alert("Something is wrong; try again later.");
      }
    }
  };

  const handleResetPassword = () => {
    const password = inpval?.password.trim();
    const confirmPassword = inpval?.confirmPassword.trim();
    const passwordRegex = /^(\+?\(?\d{3}\)?[\-\s]?)?\d{3}[\-\s]?\d{3,4}$/;

    if (!password || !confirmPassword) {
      setError({
        password: !password ? "Mot de passe requis" : "",
        confirmPassword: !confirmPassword
          ? "Confirmer Mot de passe requis"
          : "",
      });
    } else if (
      !password.match(passwordRegex) ||
      !confirmPassword.match(passwordRegex)
    ) {
      setError({
        password:
          "Votre mot de passe doit comporter au moins 10 caractères alphanumériques",
      });
    } else if (password !== confirmPassword) {
      setError({ confirmPassword: "Votre mot de passe ne correspond pas" });
    } else {
      handlesubmit();
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className="login-form">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <Image
              src={checkMap ? domian_pine_hill_logo : domaine_st_come}
              alt="logo"
              fluid
            />
          </div>
          <Container className="login-form">
            <div className="modal-body">
              <h6 className="text-center form-title">
                {strings.Forms.resetPassword}
              </h6>
              <Row>
                <Col xl={4} sm={8} xs={10} className="mx-auto">
                  <Form.Group className="form-group">
                    <Form.Control
                      className="text-center"
                      type="password"
                      name="password"
                      value={inpval?.password}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      placeholder={strings.Forms.passwordPlaceholder}
                      isInvalid={!!error.password}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-center"
                    >
                      {error.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      className="text-center"
                      placeholder={strings.Forms.passwordPlaceholder}
                      type="password"
                      name="confirmPassword"
                      value={inpval?.confirmPassword}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      isInvalid={!!error.confirmPassword}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-center"
                    >
                      {error.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-center flex-md-row flex-column-reverse gap-3">
                    <Button
                      onClick={handleResetPassword}
                      className="button-second p-3 border"
                      variant="green"
                    >
                      {strings.Forms.resetPassword}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
