import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
  Tab,
  Nav,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import {
  ref,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
  push,
  update,
} from "firebase/database";
import { BsArrowLeft } from "react-icons/bs";
import { FiUserPlus, FiUser, FiEdit2, FiInfo } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import {
  companiesRef,
  contactRef,
  promisesRef,
  rdb,
  tables,
} from "../../services/firebase";
import "./index.scss";
import strings from "../../utils/fr.json";
import { domaine_pine_hill_large, stComeLogo } from "../../assets/images";
import { constant } from "../../utils/constant.js";
import { Spinner } from "../index";
import {
  ADD_CONTACT_TO_COMPANY,
  CREATE_COMPANY,
  CREATE_CONTACT,
  CREATE_ENGAGEMENT,
  CRM_ASSOCIATIONS_BATCH,
  GET_COMPANY_BY_ID,
  GET_CONTACT_BY_EMAIL,
  UPDATE_CONTACT,
  UPDATE_DEAL,
  headersKey,
} from "../../utils/config";
import { formatPhoneNumber } from "../../utils/helper";
var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const pathName = window.location.pathname;
const phasecheckNord = pathName.includes("phase4nord");

class SubmitDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
      loading: false,
      activeKey: "personal",
      activeSubKey: "society",
      society: [
        {
          title: strings.Forms.companyName,
          placeholder: strings.Forms?.nameHere,
          value: "",
        },
        // {
        //   title: strings.Forms.address,
        //   placeholder: strings.Forms.addressHere,
        //   value: "",
        // },
        {
          title: strings.Forms.telephone,
          placeholder: "(000) 000-0000",
          type: "tel",
          value: "",
          phoneInput: true,
        },
        {
          title: strings.Forms.email,
          placeholder: strings.Forms.emailPlaceholder,
          value: "",
        },
        {
          title: strings.Forms.representing,
          placeholder: "John Smith",
          value: "",
        },
      ],
      particulier: [
        {
          title: strings.Forms.firstName,
          placeholder: "John",
          value: "",
        },
        {
          title: strings.Forms.lastName,
          placeholder: "Smith", // strings.Forms.nameHere,
          value: "",
        },
        // {
        //   title: strings.Forms.addresses,
        //   placeholder: strings.Forms.addressHere,
        //   value: "",
        // },
        {
          title: strings.Forms.telephone,
          placeholder: "(000) 000-0000",
          type: "tel",
          value: "",
          phoneInput: true,
        },
        {
          title: strings.Forms.email,
          placeholder: strings.Forms.emailPlaceholder,
          value: "",
        },
      ],
      peopleData: [],
      updateIndex: null,
      companyId: null,
      condition: strings.Forms.cash,
      iscompany: true,
      isConditionSubmit: false,
      disableSociety: false,
      disablePerson: false,
      dealID: this.props.activeArea?.deals[0]?.dealId,
      associatedVids: [],
      associatedCompanyIds: [],
      associationData: [],
    };
  }

  async componentDidMount() {
    const { isEdit, isCompanyEdit } = this.props;
    const { society, particulier } = this.state;
    var userID = await localStorage.getItem("@userId");
    const starCountRef = ref(rdb, `${tables.users}${userID}`);
    onValue(starCountRef, (snapshot) => {
      const userData = snapshot.val();
      if (!isCompanyEdit && !isEdit) {
        society[0].value = userData?.company;
        // society[1].value = userData?.address;
        // + (userData?.address ? ", " : "");
        // +
        // userData?.city +
        // ", " +
        // userData?.state +
        // ", " +
        // userData?.country;
        society[1].value = formatPhoneNumber(userData?.phone);
        society[2].value = userData?.email;
        society[3].value = userData?.firstname; //+ " " + userData?.lastname;
        particulier[0].value = userData?.firstname; //+ " " + userData?.lastname;
        particulier[1].value = userData?.lastname; //userData?.address;
        // + (userData?.address ? ", " : "");
        // +
        // userData?.city +
        // ", " +
        // userData?.state +
        // ", " +
        // userData?.country;
        particulier[2].value = formatPhoneNumber(userData?.phone);
        particulier[3].value = userData?.email;

        if (phasecheckNord) {
          this.state.society.push({
            title: strings.Forms.referredBy,
            placeholder: strings.Forms?.typeHere,
            value: "",
          });

          this.state.particulier.push({
            title: strings.Forms.referredBy,
            placeholder: strings.Forms?.typeHere,
            value: "",
          });
        }

        this.setState({
          particulier: this.state.particulier,
          society: this.state.society,
          activeSubKey: "society",
        });
      }
    });
    this.setState({
      activeKey: "personal",
    });
  }

  componentDidUpdate(preProps) {
    if (preProps.show !== this.props.show && this.props.show) {
      const { dealsData, isEdit, isCompanyEdit } = this.props;
      if (isCompanyEdit) {
        const { society } = this.state;
        society[0].value = dealsData?.name;
        // society[1].value = dealsData.address;
        society[1].value = dealsData.phone;
        society[2].value = dealsData.email;
        society[3].value = dealsData.represent;
        this.setState({ society: this.state.society, activeSubKey: "society" });
      } else if (isEdit) {
        const { particulier } = this.state;
        particulier[0].value = dealsData?.name;
        particulier[1].value = dealsData?.lastname; //dealsData.address;
        particulier[2].value = dealsData.phone;
        particulier[3].value = dealsData.email;
        this.setState({
          particulier: this.state.particulier,
          activeSubKey: "personnel",
        });
      }
    }
  }

  handleNextLink = async (type = "society") => {
    const { particulier } = this.state;
    const { isEdit } = this.props;
    let contactByEmailData = [];

    if (type === "individual") {
      if (isEdit) {
        const contactByEmail = await query(
          contactRef,
          orderByChild("email"),
          equalTo(particulier[3].value)
        );

        await onValue(
          contactByEmail,
          async (snapshot) => {
            contactByEmailData = snapshot.val();
            contactByEmailData = contactByEmailData
              ? Object.entries(contactByEmailData)
              : [];
            await this.createUpdateContact(contactByEmailData);
            this.closeModal();
          },
          { onlyOnce: true }
        );
      } else {
        this.setState({ iscompany: false, activeKey: "payment" });
      }
    } else {
      this.addUpdateCompanies();
      this.setState({ iscompany: true });
    }
  };

  updateCompany = async () => {
    const { dealsData } = this.props;
    const { society } = this.state;
    var element = {
      name: society[0].value,
      // address: society[1].value,
      phoneNumber: society[1].value,
      email: society[2].value,
      company: society[3].value || "",
    };
    var contactDataEdit = await this.updateIndividualContact(element);
    if (contactDataEdit) {
      // axios.put(GET_COMPANY_BY_ID(dealsData.companyID))
      fetch(GET_COMPANY_BY_ID(dealsData.companyID), {
        method: "put",
        headers: headersKey,
        body: JSON.stringify({
          properties: [
            {
              name: "name",
              value: society[0].value,
            },
            {
              name: "phone",
              value: society[1].value,
            },
            {
              name: "description",
              value: `Contact: ${society[3].value}`,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.closeModal();
        })
        .catch((e) => {});
    }
  };

  getCompanies = async () => {
    const { society } = this.state;
    var data = await this.getContactByEmail(society[2].value);
    var checkIsFound = data.category === "OBJECT_NOT_FOUND";
    if (checkIsFound) {
      var element = {
        name: society[0].value,
        // address: society[1].value,
        lastname: "",
        phoneNumber: society[1].value,
        email: society[2].value,
        company: society[3].value || "",
      };
      var contactData = await this.createIndividualContact(element);
      this.setState({ contactId: contactData.vid }, () => {
        this.createCompany();
      });
    } else {
      this.setState({ contactId: data.vid }, () => {
        this.createCompany();
      });
    }
  };

  createCompany = () => {
    const { society } = this.state;
    fetch(CREATE_COMPANY, {
      method: "post",
      headers: headersKey,
      body: JSON.stringify({
        properties: [
          {
            name: "name",
            value: society[0].value,
          },
          {
            name: "phone",
            value: society[1].value,
          },
          {
            name: "description",
            value: `Contact: ${society[3].value}`,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          companyId: res.companyId,
          associationData: [
            {
              fromObjectId: res.companyId, //company Id or contact Id
              toObjectId: this.props.activeArea?.deals[0]?.dealId, //dealId
              category: "HUBSPOT_DEFINED",
              definitionId: 6,
            },
            {
              fromObjectId: this.state.contactId, //company Id or contact Id
              toObjectId: this.props.activeArea?.deals[0]?.dealId, //dealId
              category: "HUBSPOT_DEFINED",
              definitionId: 4,
            },
          ],
        });
        this.addContactToCompany();
      })
      .catch((e) => {});
  };

  addContactToCompany = () => {
    const { companyId, contactId } = this.state;
    fetch(ADD_CONTACT_TO_COMPANY(companyId, contactId), {
      method: "put",
      headers: headersKey,
    })
      .then((res) => res.json())
      .then((res) => {
        // this.setState(
        //   {
        //     associatedCompanyIds: [
        //       ...this.props.activeArea?.deals[0]?.associations
        //         ?.associatedCompanyIds,
        //       this.state.companyId,
        //     ],
        //     associatedVids: [],
        //   },
        //   () => {
        //     // this.createDeal();
        //     // this.crmAssociations();
        //     this.setState({ activeKey: "payment" });
        //   }
        // );
        this.setState({ activeKey: "payment" });
      })
      .catch((e) => {});
  };

  callEmailAPI = async () => {
    const { peopleData } = this.state;
    var withoutContacts = peopleData.filter((item) => !item.isContactExist);
    for (let i = 0; i < peopleData.length; i++) {
      const element = peopleData[i];
      if (this.props.isEdit) {
        await this.updateIndividualContact(element);
        // this.closeModal();
      } else {
        if (withoutContacts.length > 0) {
          if (!element.isContactExist) {
            var contactData = await this.createIndividualContact(element); // Do something for contact already exists
            // if (contactData.error === "CONTACT_EXISTS") {
            //   contactData = await this.createIndividualContact(element); // Do something for contact already exists
            // }
            peopleData[i].contactData = contactData;
            this.setState({ peopleData: this.state.peopleData });
          }
        } else if (element.isContactExist) {
          await this.updateIndividualContact(element);
        }
      }

      if (!this.props.isEdit) {
        this.setState(
          {
            associationData: [
              ...this.state.associationData,
              {
                fromObjectId: element.contactData.vid, //company Id or contact Id
                toObjectId: this.props.activeArea?.deals[0]?.dealId, //dealId
                category: "HUBSPOT_DEFINED",
                definitionId: 4,
              },
            ],
          },
          () => {
            if (i === peopleData.length - 1) {
              // this.crmAssociations();
              // this.setState({ activeKey: "payment" });
            }
          }
        );
      }
    }
  };

  createIndividualContact = async (item) => {
    var contactData = await fetch(CREATE_CONTACT, {
      method: "post",
      headers: headersKey,
      body: JSON.stringify({
        properties: [
          {
            property: "firstname",
            value: item?.name || "",
          },
          {
            property: "email",
            value: item.email,
          },
          {
            property: "phone",
            value: item.phoneNumber,
          },
          {
            property: "lastname",
            value: item.lastname,
          },
          {
            property: "company",
            value: item.company ? item.company || "" : "",
          },
        ],
      }),
    });
    return contactData.json();
  };

  updateIndividualContact = async (item) => {
    var contactDatas = await fetch(
      UPDATE_CONTACT(item?.contactID || this.props.dealsData.contactID),
      {
        method: "post",
        headers: headersKey,
        body: JSON.stringify({
          properties: [
            {
              property: "firstname",
              value: item?.name || "",
            },
            {
              property: "email",
              value: item.email,
            },
            {
              property: "phone",
              value: item.phoneNumber,
            },
            {
              property: "lastname",
              value: item.lastname,
            },
            {
              property: "company",
              value: item.company ? item.company : "",
            },
          ],
        }),
      }
    );

    if (contactDatas) {
      return true;
      // contactDatas.json();
    }
  };

  crmAssociations = () => {
    fetch(CRM_ASSOCIATIONS_BATCH, {
      method: "put",
      headers: headersKey,
      body: JSON.stringify(this.state.associationData),
    })
      .then((res) => {})
      .catch((error) => {
        console.log("error", error);
      });
  };

  addUpdateCompanies = async () => {
    const { society } = this.state;
    const {
      // isCompanyEdit,
      activeArea,
    } = this.props;
    var companyIds =
      activeArea?.deals[0]?.associations?.associatedCompanyIds || [];
    let contactByEmailData = [],
      companyExistsData = [],
      contactVid,
      updates = {};
    const contactByEmail = await query(
      contactRef,
      orderByChild("email"),
      equalTo(society[2].value)
    );

    await onValue(
      contactByEmail,
      (snapshot) => {
        contactByEmailData = snapshot.val();
        contactByEmailData = contactByEmailData
          ? Object.entries(contactByEmailData)
          : [];
      },
      { onlyOnce: true }
    );

    const companyExists = await query(
      companiesRef,
      orderByChild("phone"),
      equalTo(society[1].value)
    );

    await onValue(
      companyExists,
      async (snapshot) => {
        companyExistsData = snapshot.val();
        // company exists
        contactVid = await this.createUpdateContact(
          contactByEmailData,
          "company"
        );
        const newCompanyExistsData = companyExistsData
          ? Object.entries(companyExistsData)
          : [];

        if (companyExistsData) {
          var companyData = newCompanyExistsData[0];
          var contactExist = companyData[1]?.contactVids?.includes(contactVid);
          let companyObj = {
            category: companyData[1]?.category || "Normal",
            companyId: companyData[1]?.companyId,
            contactVids: contactExist
              ? companyData[1]?.contactVids
              : companyData[1]?.contactVids
              ? [...companyData[1]?.contactVids, contactVid]
              : [contactVid],
            description: `Contact: ${society[0].value}`,
            name: society[3].value || "",
            name_lower: society[3].value?.toLowerCase() || "",
            phone: society[1].value,
            type: "company",
            website: "",
          };

          this.setState({
            companyId: companyObj.companyId,
            associatedCompanyIds:
              companyIds.length > 0
                ? [...companyIds, companyObj.companyId]
                : [companyObj.companyId],
          });
          // Update company query
          updates[tables.companies + companyData[0]] = companyObj;
          await update(ref(rdb), updates);
          this.moveToPayment();
        } else {
          // company not exists
          // create company to push one contact Id
          let companyObj = {
            category: "Normal",
            companyId: new Date().getTime(),
            contactVids: [contactVid],
            description: `Contact: ${society[0].value}`,
            name: society[3].value || "",
            name_lower: society[3].value?.toLowerCase() || "",
            phone: society[1].value,
            type: "company",
            website: "",
          };
          this.setState({
            companyId: companyObj.companyId,
            associatedCompanyIds:
              companyIds.length > 0
                ? [...companyIds, companyObj.companyId]
                : [companyObj.companyId],
          });
          // Add Company
          let newPostRef = push(companiesRef);
          await set(newPostRef, companyObj);
          this.moveToPayment();
        }
      },
      { onlyOnce: true }
    );
  };

  moveToPayment = () => {
    const { isCompanyEdit } = this.props;
    if (isCompanyEdit) {
      this.updateCompany();

      // this.closeModal();
    } else {
      this.getCompanies();
      // this.setState({ activeKey: "payment" });
    }
  };

  createUpdateContact = async (contactByEmailData, type = "personal") => {
    const { society, particulier } = this.state;
    const { activeArea } = this.props;
    var contactIds = activeArea?.deals[0]?.associations?.associatedVids || [];

    var vid = new Date().getTime();
    var name = type === "personal" ? particulier[0].value : society[0].value,
      email = type === "personal" ? particulier[3].value : society[2].value,
      phoneNumber =
        type === "personal" ? particulier[2].value : society[1].value,
      lastname = type === "personal" ? particulier[1].value : "";
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    const timeNow = new Date().getTime();
    let obj = {
      name: name || "",
      name_lower: name?.toLowerCase() || "",
      email: email,
      lastname: lastname,
      phoneNumber: phoneNumber,
      category: "Normal",
      createdDate:
        contactByEmailData.length > 0
          ? contactByEmailData[0][1].createdDate
          : new Date().getTime(),
      vid: contactByEmailData.length > 0 ? contactByEmailData[0][1].vid : vid,
      type: "personal",
      // address: address,
      origine_du_lead: checkMap
        ? "Carte interactive" //Carte interactive - PH
        : "Carte interactive - SC",
      lastmodifieddate: timeNow,
    };

    if (type === "personal") {
      this.setState({
        associatedVids:
          contactIds.length > 0 ? [...contactIds, obj.vid] : [obj.vid],
      });
    }

    if (contactByEmailData.length > 0) {
      // Update data
      var updates = {};
      updates[tables.contacts + contactByEmailData[0][0]] = obj;
      await update(ref(rdb), updates);
      return contactByEmailData[0][1].vid;
    } else {
      // Add contact if not exists email id
      var newPostRef = push(contactRef);
      set(newPostRef, obj);
      return vid;
    }
  };

  closeModal = () => {
    const { society, particulier } = this.state;

    var newSociety = society.map((item, index) => {
      return { ...item, value: "" };
    });
    var newParticulier = particulier.map((item, index) => {
      return { ...item, value: "" };
    });

    this.setState(
      {
        peopleData: [],
        associatedVids: [],
        associatedCompanyIds: [],
        society: newSociety,
        activeKey: "personal",
        activeSubKey: "society",
        particulier: newParticulier,
        isConditionSubmit: false,
        disableSociety: false,
        disablePerson: false,
      },
      () => {
        this.props.handleSubmitDetails();
        // this.props.handleTerrains();
      }
    );
  };

  societySubmit = () => {
    const { society } = this.state;
    this.setState({ disableSociety: true });
    var emptyCount = society.filter((d) =>
      phasecheckNord
        ? d.value?.trim() === "" && d.title !== strings.Forms.referredBy
        : d.value?.trim() === ""
    );
    if (emptyCount.length <= 0) {
      if (!society[1].value) {
        alert(strings.Errors.mobileEmpty);
      } else if (
        society[1].value?.replace(/\D/g, "")?.length < 10 ||
        society[1].value?.replace(/\D/g, "")?.length > 10
      ) {
        alert(strings.Errors.invalidNumber);
      } else if (!society[2]?.value?.match(emailRegex)) {
        alert(strings.Errors.invalidEmail);
      } else {
        this.handleNextLink();
      }
      this.setState({ disableSociety: false });
    } else {
      alert(strings.Errors.allFieldsRequired);
      this.setState({ disableSociety: false });
    }
  };

  personsSubmit = () => {
    const { peopleData, particulier } = this.state;
    this.setState({ disablePerson: true });
    var newData = particulier.filter((item) =>
      phasecheckNord
        ? item.title === strings.Forms.referredBy
          ? true
          : item.value
        : item.value
    );

    const checkCondition =
      newData.length === 1 && newData[0].value === "" ? false : true;

    if (newData.length === particulier.length) {
      this.handlePerson("callNext");
    } else if (
      newData.length > 0 &&
      newData.length < particulier.length &&
      checkCondition
    ) {
      alert(strings.Errors.allFieldsRequired);
      this.setState({ disablePerson: false });
    } else {
      if (peopleData.length > 0) {
        this.handleNextLink("individual");
      } else {
        alert(strings.Errors.addPerson);
        this.setState({ disablePerson: false });
      }
    }
  };

  submitForm = () => {
    this.setState({ isConditionSubmit: true }, () => {
      this.createEngagement();
    });
  };

  createEngagement = () => {
    const { iscompany, society, peopleData } = this.state;
    var checkStatus =
      this.props.activeArea?.deals[0]?.properties?.statut_des_promesses_d_achat
        ?.value;
    var peopleName = peopleData.map((item) => item?.name).join(", ");
    var name = iscompany ? society[0].value : peopleName;
    var bodyName = checkStatus
      ? checkStatus === strings.Status.row1
        ? `${strings.Status.secondOfferBy} ${name}`
        : checkStatus === strings.Status.row2
        ? `${strings.Status.thirdOfferBy} ${name}`
        : `${strings.Status.firstOfferBy} ${name}`
      : `${strings.Status.firstOfferBy} ${name}`;

    // "[Nom Complet OR Représentant] - Référé par [Référé par input]."
    // Érica Santos - Référé par Dixeeta
    let referredBy = "";
    if (phasecheckNord) {
      if (iscompany) {
        referredBy = society[4].value ? `Référé par ${society[4].value}` : "";
      } else {
        referredBy = peopleData[0].referredBy
          ? `Référé par ${peopleData[0].referredBy}`
          : "";
      }
    }

    fetch(CREATE_ENGAGEMENT, {
      method: "post",
      headers: headersKey,
      body: JSON.stringify({
        metadata: {
          body: `${bodyName} — Financement: ${this.state.condition} — Prix ${
            this.props.phaseMap?.phase === constant.phase4 ||
            this.props.phaseMap?.phase === constant.phase4nord
              ? this.props.activeArea?.deals[0]?.properties?.tarif_fixe_pour_ph4
                  ?.value
              : this.props.activeArea?.deals[0]?.properties?.prix_au_pi2?.value
          }$<br>${referredBy}`,
        },
        engagement: {
          type: "NOTE",
          active: true,
        },
        associations: {
          dealIds: [this.props.activeArea?.deals[0]?.dealId],
          ownerIds: [],
          contactIds: [],
          companyIds: [],
          ticketIds: [],
        },
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.crmAssociations();
          this.createUpdateDeal();
        },
        (error) => {}
      );
  };

  createUpdateDeal = async () => {
    const {
      iscompany,
      society,
      peopleData,
      companyId,
      particulier,
      associatedVids,
      associatedCompanyIds,
    } = this.state;
    const { activeArea, phaseMap } = this.props;

    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    // var checkStatus =
    //   this.props.activeArea?.deals[0]?.properties?.statut_des_promesses_d_achat
    //     ?.value;
    var stageValue = checkMap
      ? constant.dealsStage.pinehill
      : constant.dealsStage.stcome;

    var peopleName = peopleData.map((item) => item?.name).join(", ");
    var name = iscompany ? society[0].value : peopleName;

    var status = strings.Status.complete;
    // checkStatus
    //   ? checkStatus === strings.Status.row1
    //     ? strings.Status.row2
    //     : checkStatus === strings.Status.row2
    //     ? strings.Status.complete
    //     : strings.Status.row1
    //   : strings.Status.row1;
    var bodyName = `${strings.Status.thirdOfferBy} ${name}`;
    // var bodyName = checkStatus
    //   ? checkStatus === strings.Status.row1
    //     ? `${strings.Status.secondOfferBy} ${name}`
    //     : checkStatus === strings.Status.row2
    //     ? `${strings.Status.thirdOfferBy} ${name}`
    //     : `${strings.Status.firstOfferBy} ${name}`
    //   : `${strings.Status.firstOfferBy} ${name}`;
    this.setState({ loading: true });
    var engagement = `${bodyName} — Financement: ${this.state.condition}`;
    var updates = {};
    let associations = iscompany ? associatedCompanyIds : associatedVids;
    let associationsKey = iscompany ? "associatedCompanyIds" : "associatedVids";
    const timeNow = new Date().getTime();
    const dealSatge =
      phaseMap.phase === constant.phase1
        ? stageValue.phase1.purchaseSend
        : phaseMap.phase === constant.phase2
        ? stageValue.phase2.purchaseSend
        : phaseMap.phase === constant.phase3
        ? stageValue.phase3.purchaseSend
        : phaseMap.phase === constant.phase4
        ? stageValue.phase4.purchaseSend
        : stageValue.phase4nord.purchaseSend;
    const dealObj = {
      ...activeArea.deals[0],
      associations: {
        ...activeArea.deals[0]?.associations,
        [associationsKey]: associations,
      },
      notes: activeArea.deals[0]?.notes
        ? [...activeArea.deals[0]?.notes, { title: engagement }]
        : [{ title: engagement }],
      properties: {
        ...activeArea?.deals[0]?.properties,
        statut_des_promesses_d_achat: { timestamp: timeNow, value: status },
        booking_source: { timestamp: timeNow, value: "Carte" },
        reservation_via_la_carte_interactive: {
          timestamp: timeNow,
          value: "Nouvelle réservation",
        },
        qui_a_fait_la_vente_du_terrain_: {
          timestamp: timeNow,
          value: "Carte Interactive",
        },
        // dealstage:
        //   // status === strings.Status.complete
        //   //   ? phaseMap.phase === constant.phase1
        //   //     ? stageValue.phase1.purchaseAccept
        //   //     : phaseMap.phase === constant.phase2
        //   //     ? stageValue.phase2.purchaseAccept
        //   //     : phaseMap.phase === constant.phase3
        //   //     ? stageValue.phase3.purchaseAccept
        //   //     : stageValue.phase4.purchaseAccept
        //   //   :
        //   {
        //     timestamp: timeNow,
        //     value: dealSatge,
        //     // remove temporary
        //     // phaseMap.phase === constant.phase1
        //     //   ? stageValue.phase1.purchaseSend
        //     //   : phaseMap.phase === constant.phase2
        //     //   ? stageValue.phase2.purchaseSend
        //     //   : phaseMap.phase === constant.phase3
        //     //   ? stageValue.phase3.purchaseSend
        //     //   : stageValue.phase4.purchaseSend,
        //   },
      },
    };
    updates[tables.deals + activeArea.deals[0].key] = dealObj;
    await fetch(UPDATE_DEAL(this.props.activeArea?.deals[0]?.dealId), {
      method: "put",
      headers: headersKey,
      body: JSON.stringify({
        properties: [
          {
            name: "statut_des_promesses_d_achat",
            value: status,
          },
          {
            name: "reservation_via_la_carte_interactive",
            value: "Nouvelle réservation",
          },
          {
            name: "qui_a_fait_la_vente_du_terrain_",
            value: "Carte Interactive",
          },
          // {
          //   name: "dealstage",
          //   value: dealSatge,
          // },
          { name: "booking_source", value: "Carte" },
        ],
      }),
    });

    await update(ref(rdb), updates);
    var userID = await localStorage.getItem("@userId");

    if (peopleData.length > 0) {
      for (let i = 0; i < peopleData.length; i++) {
        const element = peopleData[i];
        let promiseObj = {
          userID,
          contactID: element?.contactID,
          companyID: "",
          dealID: this.props.activeArea?.deals[0]?.dealId,
          map: process.env.REACT_APP_MAP,
        };

        await this.addPromises(promiseObj);
      }
    } else {
      let promiseObj = {
        userID,
        contactID: "",
        companyID: companyId,
        dealID: this.props.activeArea?.deals[0]?.dealId,
        map: process.env.REACT_APP_MAP,
      };
      await this.addPromises(promiseObj);
    }

    var newSociety = society.map((item, index) => {
      return { ...item, value: "" };
    });
    var newParticulier = particulier.map((item, index) => {
      return { ...item, value: "" };
    });

    // this.sendMail();

    this.setState(
      {
        peopleData: [],
        associatedVids: [],
        associatedCompanyIds: [],
        society: newSociety,
        activeKey: "personal",
        particulier: newParticulier,
        isConditionSubmit: false,
        loading: false,
      },
      () => {
        this.props.handleSubmitDetails();
        this.props.handleTerrains();
        setTimeout(() => {
          this.props.handleAbout();
        }, 500);
      }
    );
  };

  sendMail = () => {
    const { iscompany, society, peopleData } = this.state;
    const url =
      "https://us-central1-pine-hill-712db.cloudfunctions.net/sendEmail";
    const data = {
      from: JSON.parse(localStorage.getItem("@userData"))?.email || "",
      booked_person_name: iscompany ? society[0].value : peopleData[0]?.name,
      // ||
      // JSON.parse(localStorage.getItem("@userData"))?.name +
      //   " " +
      //   JSON.parse(localStorage.getItem("@userData"))?.lastname ||
      // "",
      map_type:
        process.env.REACT_APP_MAP === "stcome" ? "ST-COME" : "PINE HILL",
      land_number: this.props.activeArea.title,
      phase_type: this.props.phaseMap.phase,
    };
    const options = {
      method: "POSt",
      headers: {
        accept: "application/json",
        ...headersKey,
      },
      body: JSON.stringify(data),
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error("error:" + err));
  };

  addPromises = (obj) => {
    let newPostRef = push(promisesRef);
    set(newPostRef, obj);
  };

  handlePerson = async (type) => {
    const { updateIndex, particulier } = this.state;
    var contactByEmailData = [];
    var emptyCount = particulier.filter((d) =>
      phasecheckNord
        ? d.value?.trim() === "" && d.title !== strings.Forms.referredBy
        : d.value?.trim() === ""
    );
    const timeNow = new Date().getTime();
    if (emptyCount.length <= 0) {
      if (
        particulier[2].value?.replace(/\D/g, "")?.length < 10 ||
        particulier[2].value?.replace(/\D/g, "")?.length > 10
      ) {
        alert(strings.Errors.invalidNumber);
        this.setState({ disablePerson: false });
      } else if (!particulier[3]?.value?.match(emailRegex)) {
        alert(strings.Errors.invalidEmail);
        this.setState({ disablePerson: false });
      } else {
        var obj = {
          name: particulier[0].value || "",
          name_lower: particulier[0].value?.toLowerCase() || "",
          lastname: particulier[1].value || "",
          phoneNumber: particulier[2].value || "",
          email: particulier[3].value || "",
          referredBy: (phasecheckNord && particulier[4].value) || "",
          category: "Normal",
          createdDate: new Date().getTime(),
          vid: new Date().getTime(),
          type: "personal",
          lastmodifieddate: timeNow,
        };
        var data = await this.getContactByEmail(obj.email);
        var checkIsFound = data.category === "OBJECT_NOT_FOUND";

        obj = {
          ...obj,
          isContactExist: !checkIsFound,
          contactData: checkIsFound ? null : data,
        };

        const contactByEmail = await query(
          contactRef,
          orderByChild("email"),
          equalTo(particulier[3].value)
        );

        await onValue(
          contactByEmail,
          async (snapshot) => {
            contactByEmailData = snapshot.val() || [];
            contactByEmailData = Object.entries(contactByEmailData);
            var contactVID = await this.createUpdateContact(contactByEmailData);
            obj = {
              ...obj,
              contactID: contactVID,
            };
            if (updateIndex !== null) {
              this.state.peopleData[updateIndex] = obj;
            } else {
              var newPeopleData = [...this.state.peopleData, obj];
            }
            this.setState(
              {
                peopleData:
                  updateIndex !== null ? this.state.peopleData : newPeopleData,
              },
              () => {
                this.callEmailAPI();
                var newParticulier = particulier.map((item, index) => {
                  return { ...item, value: "" };
                });
                this.setState(
                  {
                    particulier: newParticulier,
                    updateIndex: null,
                  },
                  () => {
                    if (type === "callNext") {
                      this.handleNextLink("individual");
                    }
                  }
                );
              }
            );
          },
          { onlyOnce: true }
        );
      }
    } else {
      alert(strings.Errors.allFieldsRequired);
      this.setState({ disablePerson: false });
    }
  };

  getContactByEmail = async (email) => {
    var data = await fetch(GET_CONTACT_BY_EMAIL(email), {
      headers: headersKey,
    });
    return data.json();
  };

  handleBack = () => {
    if (this.state.activeKey === "payment") {
      this.setState({ activeKey: "personal" });
    } else {
      // this.props.handleSubmitDetails();
      this.closeModal();
    }
  };

  render() {
    const { show, handleSubmitDetails, isCompanyEdit, isEdit } = this.props;
    const {
      // activeKey,
      society,
      updateIndex,
      peopleData,
      particulier,
      disableSociety,
      disablePerson,
      loading,
    } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    var checkAvailable =
      peopleData.length < 3 ? true : updateIndex === null ? false : true;
    return (
      <>
        <Spinner loading={loading} />
        <Modal
          className="submit-details"
          show={show}
          fullscreen={this.state.fullscreen}
          onHide={handleSubmitDetails}
        >
          <Modal.Header>
            <Container fluid="xl">
              <Row>
                <Col lg={5} md={4} sm={4} xs={8}>
                  <div className="h-100 d-flex flex-wrap align-items-center">
                    <Button
                      variant="white"
                      className="rounded-pill back-btn"
                      onClick={this.handleBack}
                    >
                      <BsArrowLeft />
                    </Button>
                  </div>
                </Col>
                <Col lg={2} md={3} sm={4} xs={4} className="text-center">
                  <Image
                    src={checkMap ? domaine_pine_hill_large : stComeLogo}
                    alt="logo"
                    fluid
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container fluid="xl">
              <div className="tab-sec-main">
                <Tab.Container
                  className="submit"
                  defaultActiveKey={this.state.activeKey}
                  activeKey={this.state.activeKey}
                >
                  <Nav>
                    <Nav.Item
                    // onClick={() => this.setState({ activeKey: "personal" })}
                    >
                      <Nav.Link eventKey="personal">{`1. ${strings.Forms.informations}`}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                    // onClick={() => this.setState({ activeKey: "payment" })}
                    >
                      <Nav.Link eventKey="payment">{`2. ${strings.Forms.terms}`}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="personal" className="tab-1">
                      {/* Second Tab Start */}
                      <div className="tab-sec-sub">
                        <Tab.Container
                          // defaultActiveKey="society"
                          defaultActiveKey={this.state.activeSubKey}
                          activeKey={this.state.activeSubKey}
                        >
                          <Nav>
                            <Nav.Item
                              disabled={isEdit}
                              onClick={() => {
                                if (!isEdit) {
                                  this.setState({ activeSubKey: "society" });
                                }
                              }}
                            >
                              <Nav.Link eventKey="society">
                                {strings.Forms.society}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                              onClick={() => {
                                if (!isCompanyEdit) {
                                  this.setState({ activeSubKey: "personnel" });
                                }
                              }}
                            >
                              <Nav.Link eventKey="personnel">
                                {strings.Forms.individual}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="society" className="tab-1-a">
                              <Row>
                                {society.map((item, index) => {
                                  return (
                                    <Col sm={6} key={index.toString()}>
                                      <Form.Group className="form-group">
                                        <Form.Label>{item.title}</Form.Label>
                                        {/* <PhoneInput
                                            className="custom-form-control form-control"
                                            placeholder={item.placeholder}
                                            value={item.value}
                                            onChange={text => {
                                              society[index].value = text;
                                              this.setState({
                                                society: this.state.society,
                                              });
                                            }}
                                            // international={true}
                                          /> */}
                                        {item.phoneInput ? (
                                          <Form.Control
                                            placeholder={item.placeholder}
                                            value={item.value}
                                            maxLength={14}
                                            onChange={(text) => {
                                              society[index].value =
                                                formatPhoneNumber(
                                                  text.target.value
                                                );

                                              this.setState({
                                                society: this.state.society,
                                              });
                                            }}
                                          />
                                        ) : (
                                          <Form.Control
                                            // type={
                                            //   item.type ? item.type : "text"
                                            // }
                                            placeholder={item.placeholder}
                                            value={item.value}
                                            // maxLength={
                                            //   item.type === "tel" ? 10 : null
                                            // }
                                            onChange={(text) => {
                                              society[index].value =
                                                text.target.value;
                                              this.setState({
                                                society: this.state.society,
                                              });
                                            }}
                                          />
                                        )}
                                      </Form.Group>
                                    </Col>
                                  );
                                })}
                              </Row>
                              <div className="footer-submit-details">
                                <Container fluid="xl">
                                  <div className="footer-wraper">
                                    <Row>
                                      <Col sm={6}>
                                        <Button
                                          variant="green"
                                          size="lg"
                                          disabled={disableSociety}
                                          className="w-100 reserver-btn"
                                          onClick={this.societySubmit}
                                        >
                                          {/* Company next button */}
                                          {strings.Forms.next}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Container>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="personnel" className="tab-1-b">
                              <Row>
                                {peopleData.map((item, index) => {
                                  return (
                                    <Col md={6} key={index.toString()}>
                                      <Form.Group className="form-group">
                                        <div className="single-person">
                                          <div className="left">
                                            <FiUser className="person-icon" />
                                            <div className="person-detail">
                                              <span className="person-id">
                                                {strings.Forms.nobody} #
                                                {index + 1}
                                              </span>
                                              <span className="person-name">
                                                {item?.name}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="right">
                                            <Button
                                              onClick={() => {
                                                particulier[0].value =
                                                  item?.name;
                                                particulier[1].value =
                                                  item.lastname;
                                                particulier[2].value =
                                                  item.phoneNumber;
                                                particulier[3].value =
                                                  item.email;

                                                if (phasecheckNord) {
                                                  particulier[4].value =
                                                    item.referredBy;
                                                }

                                                this.setState({
                                                  particulier:
                                                    this.state.particulier,
                                                  updateIndex: index,
                                                });
                                              }}
                                              style={{ padding: 5 }}
                                              variant="transparent"
                                            >
                                              <FiEdit2 className="person-edit" />
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                if (updateIndex === null) {
                                                  peopleData.splice(index, 1);
                                                  this.setState({
                                                    peopleData:
                                                      this.state.peopleData,
                                                  });
                                                }
                                              }}
                                              style={{ padding: 5 }}
                                              variant="transparent"
                                            >
                                              <CgClose className="person-close" />
                                            </Button>
                                          </div>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  );
                                })}
                                {!checkAvailable ? (
                                  <Col xs={12}>
                                    <p className="limit-warning">
                                      {strings.Forms.reachedMax}
                                    </p>
                                  </Col>
                                ) : null}
                              </Row>
                              <Row>
                                {checkAvailable &&
                                  particulier.map((item, index) => {
                                    return (
                                      <Col sm={6} key={index.toString()}>
                                        <Form.Group className="form-group">
                                          <Form.Label>{item.title}</Form.Label>
                                          {/* <PhoneInput
                                              className="custom-form-control form-control"
                                              placeholder={item.placeholder}
                                              value={item.value}
                                              onChange={text => {
                                                particulier[index].value = text;
                                                this.setState({
                                                  particulier:
                                                    this.state.particulier,
                                                });
                                              }}
                                              // international={true}
                                              // class="form-control"
                                            />*/}
                                          {item.phoneInput ? (
                                            <Form.Control
                                              // type={
                                              //   item.type ? item.type : "text"
                                              // }
                                              placeholder={item.placeholder}
                                              value={item.value}
                                              // maxLength={
                                              //   item.type === "tel" ? 10 : null
                                              // }
                                              maxLength={14}
                                              onChange={(text) => {
                                                particulier[index].value =
                                                  formatPhoneNumber(
                                                    text.target.value
                                                  );

                                                this.setState({
                                                  particulier:
                                                    this.state.particulier,
                                                });
                                              }}
                                            />
                                          ) : (
                                            <Form.Control
                                              // type={
                                              //   item.type ? item.type : "text"
                                              // }
                                              placeholder={item.placeholder}
                                              value={item.value}
                                              // maxLength={
                                              //   item.type === "tel" ? 10 : null
                                              // }
                                              onChange={(text) => {
                                                particulier[index].value =
                                                  text.target.value;
                                                this.setState({
                                                  particulier:
                                                    this.state.particulier,
                                                });
                                              }}
                                            />
                                          )}
                                        </Form.Group>
                                      </Col>
                                    );
                                  })}
                              </Row>
                              <div className="footer-submit-details">
                                <Container fluid="xl">
                                  <div className="footer-wraper">
                                    <Row>
                                      <Col
                                        sm={6}
                                        xs={7}
                                        className="order-sm-1 order-2"
                                      >
                                        <Button
                                          variant="green"
                                          size="lg"
                                          disabled={disablePerson}
                                          className="w-100 reserver-btn"
                                          onClick={this.personsSubmit}
                                        >
                                          {strings.Forms.next}
                                          {/* Particuler next button */}
                                        </Button>
                                      </Col>
                                      <Col
                                        sm={6}
                                        xs={5}
                                        className="order-sm-2 order-1"
                                      >
                                        {isEdit ? null : (
                                          <Button
                                            variant="outline-green"
                                            size="lg"
                                            disabled={!checkAvailable}
                                            className="w-100 reserver-btn"
                                            onClick={this.handlePerson}
                                          >
                                            <FiUserPlus />
                                            <div className="d-none d-sm-inline">
                                              {updateIndex !== null
                                                ? strings.Forms.updatePerson
                                                : strings.Forms.addPerson}
                                            </div>
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Container>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="payment" className="tab-2">
                      <Row>
                        <Col xl={7} md={7}>
                          <Form.Group className="form-group b-bottom">
                            <Form.Label>{strings.Forms.condition}</Form.Label>
                            <Form.Select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ condition: e.target.value });
                              }}
                            >
                              {/* <option>Faites votre sélection</option> */}
                              <option value={strings.Forms.cash}>
                                {strings.Forms.cash}
                              </option>
                              <option value={strings.Forms.financing}>
                                {strings.Forms.financing}
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <div className="payment-points">
                            <div className="points">
                              <div className="points-top">
                                <FiInfo className="points-icon" />
                                <p className="text">
                                  {strings.Forms.deadlinePurchacing}
                                </p>
                              </div>
                              <div className="points-bottom">
                                <p className="text">{`48 ${strings.Forms.hours}`}</p>
                              </div>
                            </div>
                            <div className="points">
                              <div className="points-top">
                                <FiInfo className="points-icon" />
                                <p className="text">
                                  {strings.Forms.closingDate}
                                </p>
                              </div>
                              <div className="points-bottom">
                                <p className="text">
                                  {`30 ${strings.Forms.offerToPurchase}`}
                                </p>
                              </div>
                            </div>
                            <div className="points">
                              <div className="points-top">
                                <FiInfo className="points-icon" />
                                <p className="text">{strings.Forms.deposit}</p>
                              </div>
                              <div className="points-bottom">
                                <p className="text">{`${
                                  this.props.activeArea?.deals[0]?.properties
                                    ?.depot_requis?.value || ""
                                }$ ${strings.Forms.fixed}`}</p>
                              </div>
                            </div>
                          </div>
                          <div className="footer-submit-details">
                            <Container fluid="xl">
                              <div className="footer-wraper second">
                                <Row>
                                  <Col md={7}>
                                    <Button
                                      disabled={this.state.isConditionSubmit}
                                      variant="green"
                                      size="lg"
                                      className="w-100 reserver-btn"
                                      onClick={this.submitForm}
                                    >
                                      {strings.Forms.submit}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Container>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SubmitDetails;
