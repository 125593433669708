import React from "react";
import { Modal, Image, Button, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { constant } from "../../utils/constant";
import { Timer } from "../index";
import "./index.scss";
import strings from "../../utils/fr.json";
import { phaseLogo } from "../../utils/theme";
import { remainingTime } from "../timer";

class ViewMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
      phaseData: [
        { title: strings.ViewMore.lotAvailable, count: 0 },
        { title: strings.ViewMore.totalLands, count: 1 },
      ],
      timeRemain: remainingTime(props.dateLimit, props.timeLimit),
      dateLimit: props.dateLimit,
      timeLimit: props.timeLimit,
    };
    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        timeRemain: remainingTime(this.props.dateLimit, this.props.timeLimit),
      });
    }, 1000);
  }

  componentDidUpdate(preProps) {
    if (preProps.dateLimit !== this.props.dateLimit) {
      this.setState({
        dateLimit: this.props.dateLimit,
      });
    }

    if (preProps.timeLimit !== this.props.timeLimit) {
      this.setState({
        timeLimit: this.props.timeLimit,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      show,
      handlePhases,
      disponibles,
      totalLand,
      phase,
      phaseTypes,
      // zoom
    } = this.props;
    const { phaseData } = this.state;
    // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <Modal
        className="view-more-modal"
        size="md"
        show={show}
        onHide={handlePhases}
        centered
      >
        <Modal.Header className="justify-content-center">
          <Image
            src={phaseLogo(`phase ${phase}`, phaseTypes === "Dome")}
            alt="logo"
            fluid
          />
        </Modal.Header>
        <Modal.Body>
          <h2 className="phase-title">{`Phase ${
            phase === "4nord" ? "4 - Nord" : phase
          }`}</h2>
          <div className="phase-details">
            <ul>
              {phaseData.map((item, index) => {
                return (
                  <li key={index.toString()}>
                    <span className="text">{item.title}</span>
                    <span className="count">
                      {item.count === 0 ? disponibles : totalLand}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="item-ground">
            <ProgressBar
              variant="ground-progress"
              now={Math.abs((disponibles * 100) / totalLand - 100).toFixed(0)}
            />
            <span>
              {Math.abs((disponibles * 100) / totalLand - 100).toFixed(0)}%
            </span>
          </div>
          {/* Timer hide start */}
          {/* {this.state.timeRemain && phase === 4 ? ( */}
          {this.state.timeRemain &&
          this.state.dateLimit &&
          this.state.timeLimit ? (
            <div className="d-flex justify-content-center mt-4 mt-xxl-5">
              <Timer
                dateLimit={this.state.dateLimit}
                timeLimit={this.state.timeLimit}
              />
            </div>
          ) : null}
          {/* Timer hide end */}
          {/* <div className="item-ground">
            <ProgressBar
              variant="ground-progress"
              now={((zoom * 100) / 2).toFixed(0)}
            />
            <span>{((zoom * 100) / 2).toFixed(0)}%</span>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="view-more-btn"
            style={{ paddingTop: 0, paddingBottom: 0 }}
            to={`/phase${phase}`} // "/areas"
            state={{
              phase: `Phase ${phase}`,
              dateLimit: this.state.dateLimit,
              timeLimit: this.state.timeLimit,
              phaseType: phase === 4 ? phaseTypes : "",
            }}
          >
            <Button variant="green" size="lg" className="view-more-btn">
              {strings.ViewMore.seeMap}
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ViewMore;
