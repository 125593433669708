import {
  Area1,
  Area2,
  Area3,
  // Area4,
  // Area5,
  Area6,
  Area7,
  Area8,
  Area9,
  Area10,
  Area11,
  Area12,
  Area13,
  Area14,
  Area15,
  Area16,
  Area17,
  Area18,
  Area19,
  Area20,
  Area21,
  Area22,
  Area23,
  Area24,
  Area25,
  Area26,
  Area27,
  Area28,
  Area29,
  Area30,
  Area31,
  Area32,
  Area33,
  Area34,
  Area35,
  Area36,
  Area37,
  Area38,
  Area39,
  Area40,
  Area41,
  Area42,
  Area43,
  Area44,
  Area45,
  Area46,
  Area47,
  Area48,
  AreaP2_1,
  AreaP2_2,
  AreaP2_3,
  AreaP2_4,
  AreaP2_5,
  AreaP2_6,
  AreaP2_7,
  AreaP2_8,
  AreaP2_9,
  AreaP2_10,
  AreaP2_11,
  AreaP2_12,
  AreaP2_13,
  AreaP2_14,
  AreaP2_15,
  AreaP2_16,
  AreaP2_17,
  AreaP2_18,
  AreaP2_19,
  AreaP2_20,
  AreaP2_21,
  AreaP2_22,
  AreaP2_23,
  AreaP2_24,
  AreaP2_25,
  AreaP2_26,
  AreaP2_27,
  AreaP2_28,
  AreaP2_29,
  AreaP2_30,
  AreaP2_31,
  AreaP2_32,
  AreaP2_33,
  AreaP2_34,
  AreaP2_35,
  AreaP2_36,
  AreaP2_37,
  AreaP2_38,
  AreaP2_39,
  AreaP2_40,
  AreaP2_41,
  AreaC2_01,
  AreaC2_02,
  AreaC2_03,
  // AreaC2_04,
  AreaC2_05,
  AreaC2_06,
  AreaC2_07,
  AreaC2_08,
  AreaC2_09,
  AreaC2_10,
  AreaC2_11,
  AreaC2_12,
  AreaC2_13,
  AreaC2_14,
  AreaC2_15,
  AreaC2_16,
  AreaC2_17,
  AreaC2_18,
  AreaC2_19,
  AreaC2_20,
  AreaC2_21,
  Home_Icon,
} from "../components/mapSVGs/stCome/stComeAreaPart";
import { colors } from "./theme";

const pink = colors.pink;
const yellow = colors.yellow;
const green = colors.green;
// const green1 = colors.green1;

const phase1 = "";
const phase2 = "";

const stcomeAreaSet1 = [
  {
    x: 2154,
    y: 2154,
    title: "C1-1",
    badge: "",
    ax: 2066,
    ay: 2021,
    area: Area1,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2370,
    y: 2000,
    title: "C1-2",
    badge: "",
    ax: 2282,
    ay: 1909,
    area: Area2,
    phaseType: phase1,
    color: green,
  },
  // {
  //   x: 2069.14,
  //   y: 2008.17,
  //   title: "3",
  //   badge: "",
  //   ax: 1992,
  //   ay: 1887,
  //   area: Area3,
  //   phaseType: phase1,
  //   color: green,
  // },
  // {
  //   x: 2195.08,
  //   y: 1943,
  //   title: "4",
  //   badge: "",
  //   ax: 2095,
  //   ay: 1789,
  //   area: Area4,
  //   phaseType: phase1,
  //   color: pink,
  // },
  {
    x: 2653,
    y: 1850,
    title: "C1-3",
    badge: "",
    ax: 2430,
    ay: 1625,
    area: Area3,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2442,
    y: 1752,
    title: "C1-6",
    badge: "",
    ax: 2283,
    ay: 1556,
    area: Area6,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 2070,
    y: 2003,
    title: "C1-7",
    badge: "",
    ax: 1967,
    ay: 1914,
    area: Area7,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2391,
    y: 1688,
    title: "C1-8",
    badge: "",
    ax: 2228,
    ay: 1480,
    area: Area8,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1917,
    y: 1915,
    title: "C1-9",
    badge: "",
    ax: 1800,
    ay: 1834,
    area: Area9,
    phaseType: phase1,
    color: pink,
  },
  {
    x: 2167,
    y: 1770,
    title: "C1-10",
    badge: "",
    ax: 2081,
    ay: 1708,
    area: Area10,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2009,
    y: 1747,
    title: "C1-11",
    badge: "",
    ax: 1916,
    ay: 1696,
    area: Area11,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1885,
    y: 1681,
    title: "C1-12",
    badge: "",
    ax: 1807,
    ay: 1611,
    area: Area12,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2219,
    y: 1636,
    title: "C1-13",
    badge: "",
    ax: 2046,
    ay: 1572,
    area: Area13,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2283,
    y: 1513,
    title: "C1-14",
    badge: "",
    ax: 2157,
    ay: 1362,
    area: Area14,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 2240,
    y: 1388,
    title: "C1-15",
    badge: "",
    ax: 2114,
    ay: 1220,
    area: Area15,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2111,
    y: 1332,
    title: "C1-16",
    badge: "",
    ax: 2065,
    ay: 1145,
    area: Area16,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1921,
    y: 1473,
    title: "C1-17",
    badge: "",
    ax: 1828,
    ay: 1330,
    area: Area17,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1733,
    y: 1528,
    title: "C1-18",
    badge: "",
    ax: 1662,
    ay: 1411,
    area: Area18,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1579,
    y: 1457,
    title: "C1-19",
    badge: "",
    ax: 1554,
    ay: 1411,
    area: Area19,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1577,
    y: 1309,
    title: "C1-20",
    badge: "",
    ax: 1554,
    ay: 1240,
    area: Area20,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1721,
    y: 1323,
    title: "C1-21",
    badge: "",
    ax: 1702,
    ay: 1239,
    area: Area21,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1875,
    y: 1332,
    title: "C1-22",
    badge: "",
    ax: 1828,
    ay: 1259,
    area: Area22,
    phaseType: phase1,
    color: green,
  },
  {
    x: 2012,
    y: 1213,
    title: "C1-23",
    badge: "",
    ax: 1898,
    ay: 1117,
    area: Area23,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1999,
    y: 1093,
    title: "C1-24",
    badge: "",
    ax: 1882,
    ay: 975,
    area: Area24,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1904,
    y: 1017,
    title: "C1-25",
    badge: "",
    ax: 1829,
    ay: 890,
    area: Area25,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1795,
    y: 954,
    title: "C1-26",
    badge: "",
    ax: 1689,
    ay: 804,
    area: Area26,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1596,
    y: 1095,
    title: "C1-27",
    badge: "",
    ax: 1553,
    ay: 991,
    area: Area27,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1535,
    y: 983,
    title: "C1-28",
    badge: "",
    ax: 1478,
    ay: 918,
    area: Area28,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1628,
    y: 886,
    title: "C1-29",
    badge: "",
    ax: 1525,
    ay: 841,
    area: Area29,
    phaseType: phase1,
    color: pink,
  },
  {
    x: 1685,
    y: 784,
    title: "C1-30",
    badge: "",
    ax: 1587,
    ay: 716,
    area: Area30,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1641,
    y: 678,
    title: "C1-31",
    badge: "",
    ax: 1542,
    ay: 579,
    area: Area31,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1521,
    y: 614,
    title: "C1-32",
    badge: "",
    ax: 1498,
    ay: 468,
    area: Area32,
    phaseType: phase1,
    color: pink,
  },
  {
    x: 1383,
    y: 757,
    title: "C1-33",
    badge: "",
    ax: 1359,
    ay: 606,
    area: Area33,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1302,
    y: 897,
    title: "C1-34",
    badge: "",
    ax: 1260,
    ay: 770,
    area: Area34,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1350,
    y: 611,
    title: "C1-35",
    badge: "",
    ax: 1193,
    ay: 399,
    area: Area35,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1285,
    y: 509,
    title: "C1-36",
    badge: "",
    ax: 1138,
    ay: 304,
    area: Area36,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1220,
    y: 398,
    title: "C1-37",
    badge: "",
    ax: 1132,
    ay: 174,
    area: Area37,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1129,
    y: 305,
    title: "C1-38",
    badge: "",
    ax: 1094,
    ay: 87,
    area: Area38,
    phaseType: phase1,
    color: pink,
  },
  {
    x: 986,
    y: 400,
    title: "C1-39",
    badge: "",
    ax: 943,
    ay: 218,
    area: Area39,
    phaseType: phase1,
    color: green,
  },
  {
    x: 875,
    y: 517,
    title: "C1-40",
    badge: "",
    ax: 740,
    ay: 399,
    area: Area40,
    phaseType: phase1,
    color: green,
  },
  {
    x: 781,
    y: 631,
    title: "C1-41",
    badge: "",
    ax: 670,
    ay: 603,
    area: Area41,
    phaseType: phase1,
    color: green,
  },
  {
    x: 941,
    y: 759,
    title: "C1-42",
    badge: "",
    ax: 821,
    ay: 633,
    area: Area42,
    phaseType: phase1,
    color: yellow,
  },
  {
    x: 1023,
    y: 885,
    title: "C1-43",
    badge: "",
    ax: 915,
    ay: 770,
    area: Area43,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1111,
    y: 997,
    title: "C1-44",
    badge: "",
    ax: 1014,
    ay: 894,
    area: Area44,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1203,
    y: 1095,
    title: "C1-45",
    badge: "",
    ax: 1113,
    ay: 1016,
    area: Area45,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1315,
    y: 1180,
    title: "C1-46",
    badge: "",
    ax: 1197,
    ay: 1073,
    area: Area46,
    phaseType: phase1,
    color: pink,
  },
  {
    x: 1373,
    y: 1326,
    title: "C1-47",
    badge: "",
    ax: 1296,
    ay: 1182,
    area: Area47,
    phaseType: phase1,
    color: green,
  },
  {
    x: 1373,
    y: 1326,
    title: "",
    badge: "",
    ax: 1521,
    ay: 1552,
    area: Area48,
    phaseType: phase1,
    color: green,
  },
];

const stcomeAreaSet2 = [
  {
    x: 1464,
    y: 1630,
    title: "C2-01",
    title1: "C2.1-01",
    badge: "",
    ax: 1384,
    ay: 1538,
    area: AreaP2_1,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1785,
    y: 1970,
    title: "C2-02",
    title1: "C2.1-02",
    badge: "",
    ax: 1700,
    ay: 1804,
    area: AreaP2_2,
    phaseType: phase2,
    color: green,
  },
  // {
  //   x: 1611,
  //   y: 2114,
  //   title: "C2-03",
  //   title1: "C2.1-03",
  //   badge: "",
  //   ax: 1578,
  //   ay: 2017,
  //   area: AreaP2_3,
  //   phaseType: phase2,
  //   color: green,
  // },
  {
    x: 1608,
    y: 1951,
    title: "C2-04",
    title1: "C2.1-04",
    badge: "",
    ax: 1584,
    ay: 1841,
    area: AreaP2_4,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1506,
    y: 1847,
    title: "C2-05",
    title1: "C2.1-05",
    badge: "",
    ax: 1477,
    ay: 1736,
    area: AreaP2_5,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1362,
    y: 1816,
    title: "C2-06",
    title1: "C2.1-06",
    badge: "",
    ax: 1317,
    ay: 1736,
    area: AreaP2_6,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1405,
    y: 2010,
    title: "C2-07",
    title1: "C2.1-07",
    badge: "",
    ax: 1338,
    ay: 1881,
    area: AreaP2_7,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1424,
    y: 2183,
    title: "C2-08",
    title1: "C2.1-08",
    badge: "",
    ax: 1330,
    ay: 2115,
    area: AreaP2_8,
    phaseType: phase2,
    color: green,
  },
  // {
  //   x: 1412,
  //   y: 2224,
  //   title: "C2-09",
  //   title1: "C2.1-09",
  //   badge: "",
  //   ax: 1354,
  //   ay: 2160,
  //   area: AreaP2_9,
  //   phaseType: phase2,
  //   color: green,
  // },
  // {
  //   x: 1179,
  //   y: 2383,
  //   title: "C2-10",
  //   title1: "C2.1-10",
  //   badge: "",
  //   ax: 1038,
  //   ay: 2299,
  //   area: AreaP2_10,
  //   phaseType: phase2,
  //   color: green,
  // },
  {
    x: 1094,
    y: 2380,
    title: "C2-11",
    title1: "C2.1-11",
    badge: "",
    ax: 970,
    ay: 2316,
    area: AreaP2_11,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1115,
    y: 2230,
    title: "C2-12",
    title1: "C2.1-12",
    badge: "",
    ax: 938,
    ay: 2181,
    area: AreaP2_12,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1120,
    y: 2100,
    title: "C2-13",
    title1: "C2.1-13",
    badge: "",
    ax: 996,
    ay: 2049,
    area: AreaP2_13,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1090,
    y: 2004,
    title: "C2-14",
    title1: "C2.1-14",
    badge: "",
    ax: 927,
    ay: 1910,
    area: AreaP2_14,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1144,
    y: 1844,
    title: "C2-15",
    title1: "C2.1-15",
    badge: "",
    ax: 1068,
    ay: 1763,
    area: AreaP2_15,
    phaseType: phase2,
    color: green,
  },
  {
    x: 981,
    y: 1908,
    title: "C2-16",
    title1: "C2.1-16",
    badge: "",
    ax: 931,
    ay: 1813,
    area: AreaP2_16,
    phaseType: phase2,
    color: green,
  },
  {
    x: 805,
    y: 1871,
    title: "C2-17",
    title1: "C2.1-17",
    badge: "",
    ax: 712,
    ay: 1766,
    area: AreaP2_17,
    phaseType: phase2,
    color: green,
  },
  {
    x: 639,
    y: 1666,
    title: "C2-18",
    title1: "C2.1-18",
    badge: "",
    ax: 580,
    ay: 1557,
    area: AreaP2_18,
    phaseType: phase2,
    color: green,
  },
  {
    x: 532,
    y: 1533,
    title: "C2-19",
    title1: "C2.1-19",
    badge: "",
    ax: 454,
    ay: 1440,
    area: AreaP2_19,
    phaseType: phase2,
    color: green,
  },
  {
    x: 400,
    y: 1390,
    title: "C2-20",
    title1: "C2.1-20",
    badge: "",
    ax: 327,
    ay: 1280,
    area: AreaP2_20,
    phaseType: phase2,
    color: green,
  },
  {
    x: 292,
    y: 1241,
    title: "C2-21",
    title1: "C2.1-21",
    badge: "",
    ax: 152,
    ay: 1198,
    area: AreaP2_21,
    phaseType: phase2,
    color: green,
  },
  {
    x: 298,
    y: 1099,
    title: "C2-22",
    title1: "C2.1-22",
    badge: "",
    ax: 152,
    ay: 971,
    area: AreaP2_22,
    phaseType: phase2,
    color: green,
  },
  {
    x: 448,
    y: 1033,
    title: "C2-23",
    title1: "C2.1-23",
    badge: "",
    ax: 377,
    ay: 877,
    area: AreaP2_23,
    phaseType: phase2,
    color: green,
  },
  {
    x: 565,
    y: 1248,
    title: "C2-24",
    title1: "C2.1-24",
    badge: "",
    ax: 525,
    ay: 1161,
    area: AreaP2_24,
    phaseType: phase2,
    color: green,
  },
  {
    x: 651,
    y: 1357,
    title: "C2-25",
    title1: "C2.1-25",
    badge: "",
    ax: 583,
    ay: 1264,
    area: AreaP2_25,
    phaseType: phase2,
    color: green,
  },
  {
    x: 751,
    y: 1452,
    title: "C2-26",
    title1: "C2.1-26",
    badge: "",
    ax: 696,
    ay: 1366,
    area: AreaP2_26,
    phaseType: phase2,
    color: green,
  },
  {
    x: 845,
    y: 1569,
    title: "C2-27",
    title1: "C2.1-27",
    badge: "",
    ax: 793,
    ay: 1469,
    area: AreaP2_27,
    phaseType: phase2,
    color: green,
  },
  {
    x: 926,
    y: 1688,
    title: "C2-28",
    title1: "C2.1-28",
    badge: "",
    ax: 831,
    ay: 1581,
    area: AreaP2_28,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1084,
    y: 1601,
    title: "C2.1-29",
    title1: "C2-29",
    badge: "",
    ax: 1007,
    ay: 1432,
    area: AreaP2_29,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1251,
    y: 1623,
    title: "C2.1-30",
    title1: "C2-30",
    badge: "",
    ax: 1202,
    ay: 1493,
    area: AreaP2_30,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1312,
    y: 1484,
    title: "C2.1-31",
    title1: "C2-31",
    badge: "",
    ax: 1250,
    ay: 1350,
    area: AreaP2_31,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1156,
    y: 1305,
    title: "C2.1-32",
    title1: "C2-32",
    badge: "",
    ax: 1093,
    ay: 1208,
    area: AreaP2_32,
    phaseType: phase2,
    color: green,
  },
  {
    x: 1020,
    y: 1158,
    title: "C2.1-33",
    title1: "C2-33",
    badge: "",
    ax: 962,
    ay: 1071,
    area: AreaP2_33,
    phaseType: phase2,
    color: green,
  },
  {
    x: 886,
    y: 1023,
    title: "C2.1-34",
    title1: "C2-34",
    badge: "",
    ax: 832,
    ay: 932,
    area: AreaP2_34,
    phaseType: phase2,
    color: green,
  },
  {
    x: 767,
    y: 882,
    title: "C2.1-35",
    title1: "C2-35",
    badge: "",
    ax: 737,
    ay: 777,
    area: AreaP2_35,
    phaseType: phase2,
    color: green,
  },
  {
    x: 598,
    y: 766,
    title: "C2.1-36",
    title1: "C2-36",
    badge: "",
    ax: 517,
    ay: 674,
    area: AreaP2_36,
    phaseType: phase2,
    color: green,
  },
  {
    x: 592,
    y: 969,
    title: "C2.1-37",
    title1: "C2-37",
    badge: "",
    ax: 469,
    ay: 829,
    area: AreaP2_37,
    phaseType: phase2,
    color: green,
  },
  {
    x: 696,
    y: 1127,
    title: "C2.1-38",
    title1: "C2-38",
    badge: "",
    ax: 626,
    ay: 1022,
    area: AreaP2_38,
    phaseType: phase2,
    color: green,
  },
  {
    x: 792,
    y: 1232,
    title: "C2.1-39",
    title1: "C2-39",
    badge: "",
    ax: 724,
    ay: 1124,
    area: AreaP2_39,
    phaseType: phase2,
    color: green,
  },
  {
    x: 889,
    y: 1332,
    title: "C2.1-40",
    title1: "C2-40",
    badge: "",
    ax: 822,
    ay: 1227,
    area: AreaP2_40,
    phaseType: phase2,
    color: green,
  },
  {
    x: 986,
    y: 1422,
    title: "C2.1-41",
    title1: "C2-41",
    badge: "",
    ax: 920,
    ay: 1329,
    area: AreaP2_41,
    phaseType: phase2,
    color: green,
  },
];

const stGabrielAreaSet = [
  {
    x: 379,
    y: 1431,
    title: "1-01",
    badge: "",
    ax: 408,
    ay: 1358,
    area: AreaC2_01,
    color: green,
  },
  {
    x: 498,
    y: 1540,
    title: "1-02",
    badge: "",
    ax: 527,
    ay: 1453,
    area: AreaC2_02,
    color: green,
  },
  {
    x: 680,
    y: 1581,
    title: "1-03",
    badge: "",
    ax: 679,
    ay: 1500,
    area: AreaC2_03,
    color: green,
  },
  {
    x: 129,
    y: 1350,
    title: "1-04",
    badge: "",
    ax: 114,
    ay: 1282,
    area: Home_Icon,
    color: green,
  },
  {
    x: 354,
    y: 1156,
    title: "1-05",
    badge: "",
    ax: 431,
    ay: 1173,
    area: AreaC2_05,
    color: green,
  },
  {
    x: 520,
    y: 1240,
    title: "1-06",
    badge: "",
    ax: 547,
    ay: 1162,
    area: AreaC2_06,
    color: green,
  },
  {
    x: 620,
    y: 1300,
    title: "1-07",
    badge: "",
    ax: 645,
    ay: 1229,
    area: AreaC2_07,
    color: green,
  },
  {
    x: 732,
    y: 1257,
    title: "1-08",
    badge: "",
    ax: 739,
    ay: 1305,
    area: AreaC2_08,
    color: green,
  },
  {
    x: 846,
    y: 1251,
    title: "1-09",
    badge: "",
    ax: 862,
    ay: 1300,
    area: AreaC2_09,
    color: green,
  },
  {
    x: 780,
    y: 1077,
    title: "1-10",
    badge: "",
    ax: 813,
    ay: 1123,
    area: AreaC2_10,
    color: green,
  },
  {
    x: 824,
    y: 973,
    title: "1-11",
    badge: "",
    ax: 756,
    ay: 955,
    area: Home_Icon,
    color: green,
  },
  {
    x: 847,
    y: 868,
    title: "1-12",
    badge: "",
    ax: 793,
    ay: 810,
    area: Home_Icon,
    color: green,
  },
  {
    x: 849,
    y: 711,
    title: "1-13",
    badge: "",
    ax: 938,
    ay: 707,
    area: AreaC2_13,
    color: green,
  },
  {
    x: 962,
    y: 602,
    title: "1-14",
    badge: "",
    ax: 1055,
    ay: 583,
    area: AreaC2_14,
    color: green,
  },
  {
    x: 1086,
    y: 463,
    title: "1-15",
    badge: "",
    ax: 1156,
    ay: 483,
    area: AreaC2_15,
    color: green,
  },
  {
    x: 1261,
    y: 390,
    title: "1-16",
    badge: "",
    ax: 1266,
    ay: 429,
    area: AreaC2_16,
    color: green,
  },
  {
    x: 1491,
    y: 395,
    title: "1-17",
    badge: "",
    ax: 1436,
    ay: 413,
    area: AreaC2_17,
    color: green,
  },
  {
    x: 1445,
    y: 605,
    title: "1-18",
    badge: "",
    ax: 1365,
    ay: 609,
    area: AreaC2_18,
    color: green,
  },
  {
    x: 1449,
    y: 715,
    title: "1-19",
    badge: "",
    ax: 1383,
    ay: 677,
    area: AreaC2_19,
    color: green,
  },
  {
    x: 1391,
    y: 833,
    title: "1-20",
    badge: "",
    ax: 1384,
    ay: 758,
    area: AreaC2_20,
    color: green,
  },
  {
    x: 1173,
    y: 778,
    title: "1-21",
    badge: "",
    ax: 1208,
    ay: 707,
    area: AreaC2_21,
    color: green,
  },
];

export { stcomeAreaSet1, stcomeAreaSet2, stGabrielAreaSet };
