import strings from "./fr.json";
export const constant = {
  phase1: "phase 1",
  phase2: "phase 2",
  phase3: "phase 3",
  phase4: "phase 4",
  phase4nord: "phase 4 nord",
  Phase1: "Phase 1",
  Phase2: "Phase 2",
  Phase3: "Phase 3",
  Phase4: "Phase 4",
  pinehill: "pinehill",
  stcome: "stcome",
  dealsStage: {
    pinehill: {
      phase1: {
        landAvailable: "15476984",
        purchaseSend: "appointmentscheduled",
        purchaseSent: "qualifiedtobuy",
        purchaseAccept: "12584988",
        // notForSale: "15478078",
      },
      phase2: {
        // landAvailable: "17078496",
        landAvailable: "15476983",
        purchaseSend: "33681816",
        purchaseSent: "15330483",
        purchaseAccept: "15330484",
      },
      phase3: {
        landAvailable: "850257",
        purchaseSend: "7627045",
        purchaseSent: "850258",
        purchaseAccept: "850259",
      },
      phase4: {
        landAvailable: "16262407",
        purchaseSend: "16262408",
        purchaseSent: "16262409",
        purchaseAccept: "16262410",
        notForSale: "16304197",
        newLandAvailable: "92245134",
        reserved: "70567448",
      },
      phase4nord: {
        landAvailable: "266752317",
        purchaseSend: "266752318",
        purchaseSent: "266752319",
        purchaseAccept: "266262590",
        // notForSale: "16304197",
        newLandAvailable: "266752314",
        reserved: "266752315",
      },
    },
    stcome: {
      phase1: {
        landAvailable: "872836",
        purchaseSend: "872839",
        purchaseSent: "872840",
        purchaseAccept: "872841",
      },
      phase2: {
        landAvailable: "16327015",
        purchaseSend: "16327016",
        purchaseSent: "16327017",
        purchaseAccept: "16327018",
      },
    },
  },
  status: {
    inProcess: strings.Promises.inProcess,
    submitted: strings.Promises.submitted,
    reviewed: strings.Promises.reviewed,
    accepted: strings.Promises.accepted,
  },
};
