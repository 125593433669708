export const Vector = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34981 0.989746H2.1498L0.549805 3.75977L2.1498 6.53979H5.34981L6.9498 3.75977L5.34981 0.989746Z"
        fill="#16110B"
      />
    </svg>
  );
};

export const C01 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.379883 11.7301L9.68988 0.0600586L25.1099 12.5301L15.6699 24.2001L0.379883 11.7301Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C02 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "18"}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65791 0.978379L0.28418 15.8149L20.0695 17.6469L21.4433 2.81033L1.65791 0.978379Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C03 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.120117 12.4798L9.44012 0.799805L25.0101 13.2698L15.6901 24.7998L0.120117 12.4798Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C04 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.870117 8.77002L13.3301 0.540039L24.2401 17.2L11.7802 25.27L0.870117 8.77002Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C05 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6004 21.8102L0.94043 2.16025L15.6304 0.240234L18.2904 19.8802L3.6004 21.8102Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C06 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.429688 11.9402L9.74969 0.410156L25.3197 12.7302L15.9997 24.4102L0.429688 11.9402Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C07 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49969 0.0400391L22.9797 3.52002L20.5597 18.05L0.929688 14.72L3.49969 0.0400391Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C08 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6503 0.560059L23.3802 8.38007L12.8602 25.3L0.280273 17.4801L10.6503 0.560059Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C09 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.42023 0.97998L22.0502 4.30997L19.4802 19L-0.00976562 15.67L2.42023 0.97998Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C10 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.81024 0.390137L22.3002 3.72012L19.7202 18.4101L0.240234 15.0801L2.81024 0.390137Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C11 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.410156 15.1099L2.99017 0.419922L22.6202 3.8999L20.0502 18.4399L0.410156 15.1099Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C12 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "19"}
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.780273 3.71976L20.2703 0.00976562L23.1002 14.5497L3.60022 18.4198L0.780273 3.71976Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C13 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0996094 14.72L2.66962 0.0400391L22.1596 3.37003L19.7296 18.05L0.0996094 14.72Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C14 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "17"}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73996 0.100098L21.37 2.08008L20 16.9201L0.209961 14.9401L1.73996 0.100098Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C15 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "18"}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.44043 15.3902L2.11041 0.700195L21.9004 2.84018L20.2204 17.6702L0.44043 15.3902Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C16 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "25"}
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 6.61981L13.6 0.0498047L22.42 17.7598L9.07001 24.3298L0.25 6.61981Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C17 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.730469 9.76001L13.0005 0.52002L24.9105 16.75L13.0005 25.98L0.730469 9.76001Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C18 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "25"}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.669922 5.46027L14.6199 0.240234L21.4799 18.8403L7.52997 24.0602L0.669922 5.46027Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C19 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "23"}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0400391 2.52991L14.73 0.149902L17.85 19.7999L3.00006 22.1699L0.0400391 2.52991Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C20 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.860352 9.91016L12.7203 0.930176L24.8303 16.5402L12.9703 25.6702L0.860352 9.91016Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C21 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.570312 7.27002L13.6204 0.25L23.0404 17.65L9.83032 24.67L0.570312 7.27002Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C22 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "26"}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.189453 7.81006L13.2495 0.790039L22.6595 18.35L9.45947 25.3701L0.189453 7.81006Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C23 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "19"}
//       height={height || "23"}
//       viewBox="0 0 19 23"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.629883 2.88L15.4598 0.810059L18.1399 20.45L3.29993 22.52L0.629883 2.88Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C24 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "23"}
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.540039 2.56989L15.39 0.649902L18.05 20.2899L3.35999 22.2099L0.540039 2.56989Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C25 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "21"}
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.320312 1.1499L15.1704 0.129883L16.4803 19.9199L1.64038 20.9399L0.320312 1.1499Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C26 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "25"}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3596 24.6302L0.429688 19.6602L7.20959 0.930176L21.1396 6.05017L14.3596 24.6302Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C27 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "25"}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.09045 0.439941L21.1705 5.56006L14.2505 24.14L0.310547 19.01L7.09045 0.439941Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C28 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "24"}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99976 0.129883L20.9298 5.24988L14.1498 23.8298L0.219727 18.7098L6.99976 0.129883Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C29 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "20"}
//       height={height || "24"}
//       viewBox="0 0 20 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M5.73987 0.490234L19.9698 4.57031L14.3899 23.7502L0.159912 19.5203L5.73987 0.490234Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C30 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "24"}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.709961 11.2799L17.37 0.669922L25.4399 13.2699L8.63 23.8899L0.709961 11.2799Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C31 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71997 24.7898L25.48 12.9697L16.51 0.969727L0.75 12.9298L9.71997 24.7898Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C32 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "22"}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.410156 7.29016L19.0101 0.120117L24.2301 14.0701L5.78015 21.2401L0.410156 7.29016Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C33 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.86005 0.0600586L25.4301 12.3801L16.11 24.0601L0.540039 11.7401L9.86005 0.0600586Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C34 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "22"}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.860352 2.02014L15.7003 0.390137L17.9104 20.0401L3.07031 21.6601L0.860352 2.02014Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C35 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "23"}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.02865 0.649006L0.709961 14.1318L18.684 22.5553L25.0027 9.07249L7.02865 0.649006Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C36 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "23"}
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0400391 2.32019L14.7401 0.700195L16.9501 20.4902L2.26007 22.1102L0.0400391 2.32019Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C37 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "25"}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39996 0.180176L21.1799 5.4502L14.1099 24.0302L0.169922 18.6102L7.39996 0.180176Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C38 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "23"}
//       height={height || "25"}
//       viewBox="0 0 23 25"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.60986 0.0800781L22.2399 5.80017L14.7198 24.0801L0.929932 18.3601L8.60986 0.0800781Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C39 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4297 0.0200195L23.3098 7.39001L13.3898 24.61L0.509766 17.24L10.4297 0.0200195Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C40 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "21"}
//       height={height || "16"}
//       viewBox="0 0 21 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.650024 0.420166L20.4301 0.450073L20.41 15.4401L0.469971 15.2601L0.650024 0.420166Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
// export const C41 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "22"}
//       height={height || "24"}
//       viewBox="0 0 22 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.8971 0.192439L0.0959473 5.56494L7.27505 24.0069L21.0762 18.6344L13.8971 0.192439Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C42 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0400391 12.7L8.46008 0.27002L24.9301 11.39L16.5201 23.67L0.0400391 12.7Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C43 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "23"}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.69043 2.3299L15.5305 0.859863L17.6004 20.6499L2.76044 22.1299L0.69043 2.3299Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C44 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1602 0.72998L23.0402 8.09998L13.1202 25.3199L0.240234 17.95L10.1602 0.72998Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C45 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.450195 8.30017L13.3602 0.830078L23.3702 17.9302L10.6202 25.4102L0.450195 8.30017Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C46 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.820312 8.40015L13.7303 0.930176L23.7403 18.0402L10.9903 25.5101L0.820312 8.40015Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C47 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.139648 8.12012L13.0496 0.640137L23.0596 17.7501L10.3096 25.2301L0.139648 8.12012Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C48 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "26"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.530273 8.03992L13.4302 0.569824L23.4503 17.6699L10.5402 25.1499L0.530273 8.03992Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C49 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "26"}
//       height={height || "23"}
//       viewBox="0 0 26 23"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.659912 9.85999L18.0699 0.290039L25.09 13.34L7.68994 22.91L0.659912 9.85999Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C50 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.490234 12.0801L16.7003 0.560059L25.2202 12.72L9.01025 24.23L0.490234 12.0801Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C51 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.69043 3.59003L20.3405 0.77002L22.4104 15.47L2.77045 18.28L0.69043 3.59003Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C52 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "20"}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.169922 4.54016L19.52 0.0800781L22.7899 14.6201L3.44995 19.0901L0.169922 4.54016Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C53 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "21"}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69989 0.620117L23.8799 6.20007L19.6599 20.4302L0.629883 15.0101L4.69989 0.620117Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C54 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "16"}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0703125 0.430054L20.0103 0.310059L20.1403 15.15L0.200317 15.27L0.0703125 0.430054Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C55 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "20"}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.80957 4.88013L20.1496 0.120117L23.5796 14.6602L4.37958 19.2802L0.80957 4.88013Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C56 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.719727 15.1101L13.7898 0.140137L25.0198 9.90015L11.9497 24.8701L0.719727 15.1101Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C57 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.269531 11.1503L17.0795 0.530273L24.9995 13.1403L8.19946 23.7502L0.269531 11.1503Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C58 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2905 0.799805L25.8605 13.2698L16.5505 24.7998L0.980469 12.4798L10.2905 0.799805Z"
        fill="#985735"
      />
    </svg>
  );
};
// export const C59 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "22"}
//       height={height || "18"}
//       viewBox="0 0 22 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.119873 3.0802L19.7599 0.260254L21.8399 14.9602L2.18988 17.7703L0.119873 3.0802Z"
//         fill="#9A5735"
//       />
//     </svg>
//   );
// };
export const C60 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06982 0.950195L24.7398 9.82019L17.9798 23.1502L0.299805 14.2802L7.06982 0.950195Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C61 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "26"}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72992 0.959961L22.2199 7.12994L14.09 25.2599L0.459961 19.09L8.72992 0.959961Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C62 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "25"}
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.23996 0.379883L22.72 6.54987L14.59 24.6699L0.959961 18.4999L9.23996 0.379883Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C63 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.459961 16.64L12.63 0.919922L24.3 10.08L12.14 25.7999L0.459961 16.64Z"
        fill="#985735"
      />
    </svg>
  );
};
export const C64 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0296 0.0800781L23.7596 7.74005L13.5396 24.8201L0.80957 17.1501L11.0296 0.0800781Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C65 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "18"}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8798 3.16L20.0198 17.86L0.299805 15.58L2.0798 0.75L21.8798 3.16Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C66 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5499 0.629883L25.2899 10.8399L11.7599 25.3699L0.879883 15.1499L14.5499 0.629883Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C67 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "22"}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.12985 0.0800781L17.8398 1.84007L15.6798 21.5701L0.839844 19.8901L3.12985 0.0800781Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C68 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "20"}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2702 0.439941L22.9902 14.7799L3.83023 19.9599L-0.00976562 5.52994L19.2702 0.439941Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C69 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "20"}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2702 0.0600586L23.0002 14.4001L3.84024 19.5801L-0.00976562 5.15005L19.2702 0.0600586Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C70 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "17"}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5997 1.8801L20.2097 16.6301L0.429688 14.9801L1.72971 0.100098L21.5997 1.8801Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C71 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "23"}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9104 9.04021L18.2904 22.3002L0.44043 13.6202L7.03043 0.220215L24.9104 9.04021Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C72 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "23"}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5596 9.2199L17.9496 22.4799L0.0996094 13.7999L6.6796 0.399902L24.5596 9.2199Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C73 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "19"}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1802 0.399902L22.4902 15.0299L2.91025 18.3299L0.490234 3.5899L20.1802 0.399902Z"
        fill="#985735"
      />
    </svg>
  );
};

export const M01 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22937 15.8902L0.399414 7.93018L11.2094 0.160156L16.8894 8.26019L6.22937 15.8902Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M02 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "14"}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7394 13.56L0.899414 10.34L3.32941 0.52002L16.1594 3.66998L13.7394 13.56Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M03 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4696 0.339844L12.2097 1.8598L10.2396 14.8998L0.349609 13.3798L2.4696 0.339844Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M04 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "17"}
//       viewBox="0 0 17 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.169922 6.27002L8.12994 0.439941L16.0599 11.1L8.09991 16.9301L0.169922 6.27002Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M05 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "14"}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9302 13.9299L0.490234 9.40985L3.95026 0.129883L16.2102 4.3999L12.9302 13.9299Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M06 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.830078 6.5799L8.49008 0.149902L16.8701 10.3599L9.36008 16.6399L0.830078 6.5799Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M07 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "16"}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4202 0.810059L12.3102 2.21005L10.3402 15.36L0.450195 13.8501L2.4202 0.810059Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M08 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "18"}
//       viewBox="0 0 16 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M9.17004 17.0302L0.780029 11.9202L7.55002 0.680176L15.8 5.62012L9.17004 17.0302Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M09 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "17"}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.759766 7.20026L8.11977 0.470215L17.0898 10.0703L9.88977 16.8102L0.759766 7.20026Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M10 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "11"}
//       viewBox="0 0 15 11"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.14987 10.6998L0.859863 0.809937L14.0599 0.379883L14.3399 10.2699L1.14987 10.6998Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M11 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.469727 4.14014L9.76971 0.850098L14.2497 13.3001L4.79971 16.5901L0.469727 4.14014Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M12 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.939453 3.68018L10.3895 0.700195L14.5594 13.3002L5.10944 16.2802L0.939453 3.68018Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M13 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "17"}
//       viewBox="0 0 15 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M9.76976 0.194928L0.470215 3.92578L5.40372 16.2231L14.7033 12.4922L9.76976 0.194928Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M14 = ({ width, height }) => {
  return (
    <svg
      width={width || "12"}
      height={height || "15"}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.349609 1.11981L10.1596 0.0898438L11.7196 13.1398L1.82959 14.3198L0.349609 1.11981Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M15 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "13"}
//       viewBox="0 0 16 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6099 0.350098L15.6899 10.1001L2.66986 12.7701L0.609863 3.02014L13.6099 0.350098Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M16 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "12"}
//       height={height || "16"}
//       viewBox="0 0 12 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.96997 0.620117L11.86 2.13013L9.89001 15.3301L0 13.8102L1.96997 0.620117Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M17 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "16"}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.71027 0.930176L12.6002 2.44019L10.6302 15.6402L0.740234 14.1102L2.71027 0.930176Z"
        fill="#857700"
      />
    </svg>

    // <svg
    //   width={width || "22"}
    //   height={height || "22"}
    //   viewBox="0 0 22 22"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M13.5083 21.9338L-0.00292969 11.1301L8.05414 0.954102L21.5654 11.7578L13.5083 21.9338Z"
    //     fill="#857700"
    //   />
    // </svg>
  );
};
export const M18 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.28931 0.129883L12.1793 1.6499L10.2094 14.6899L0.319336 13.3199L2.28931 0.129883Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M19 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "16"}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84985 0.580078L12.7399 2.10004L10.7699 15.1401L0.879883 13.6201L2.84985 0.580078Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M20 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.74927 0.430176L12.4893 1.94019L10.5193 14.9802L0.779297 13.4602L2.74927 0.430176Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M21 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.51984 0.410156L12.2598 1.93018L10.2898 14.9702L0.549805 13.6002L2.51984 0.410156Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M22 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "15"}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.209961 4.80029L12.6599 0.320312L15.9399 9.6203L3.48993 14.1003L0.209961 4.80029Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M23 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "15"}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0898438 6.12012L11.9398 0.140137L16.4298 8.99011L4.56982 14.9701L0.0898438 6.12012Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M24 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.109375 7.80011L11.2194 0.620117L16.5994 8.87012L5.48938 16.0501L0.109375 7.80011Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M25 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5202 0.379883L14.8102 3.98987L9.99017 16.2799L0.700195 12.6599L5.5202 0.379883Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M26 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91962 0.120117L14.1996 3.89014L9.38965 16.1701L0.0996094 12.4101L4.91962 0.120117Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M27 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8698 15.1602L0.339844 8.55023L5.2998 0.0102539L16.9798 6.48022L11.8698 15.1602Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M28 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "18"}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.509766 7.26996L7.86975 0.689941L16.6898 10.7499L9.18976 17.1799L0.509766 7.26996Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M29 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2798 12.4099L5.12982 16.1399L0.0498047 4.00989L9.19983 0.109863L14.2798 12.4099Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M30 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2193 0.589844L14.2993 12.7398L5.14935 16.6198L0.0693359 4.31982L9.2193 0.589844Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M31 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "17"}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9379 0.723538L0.939453 4.75439L6.31527 16.7553L15.3137 12.7245L9.9379 0.723538Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M32 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "16"}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4696 0.570312L12.2096 2.08032L10.2396 15.1203L0.349609 13.6103L2.4696 0.570312Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M33 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31955 0.120117L16.5495 10.4801L8.73953 16.6101L0.519531 6.25012L8.31955 0.120117Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M34 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "18"}
//       viewBox="0 0 17 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.56995 0.390137L16.3599 6.32007L8.27997 17.0302L0.459961 11.0001L8.56995 0.390137Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M35 = ({ width, height }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.80957 1.36993L10.7096 0.189941L12.1896 13.3799L2.28958 14.5699L0.80957 1.36993Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M36 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "13"}
//       height={height || "16"}
//       viewBox="0 0 13 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.77002 1.8999L10.67 0.859863L12.14 13.9099L2.25 15.0898L0.77002 1.8999Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M37 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "17"}
//       viewBox="0 0 16 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.349854 5.61011L8.4599 0.0800781L15.9399 11.0302L7.67987 16.5701L0.349854 5.61011Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M38 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29004 0.240234L16.82 10.3002L9.16003 16.7302L0.790039 6.67023L8.29004 0.240234Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M39 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "17"}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.71939 0.609863L17.3494 8.56989L11.3394 16.4999L0.859375 8.53986L6.71939 0.609863Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M40 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "17"}
//       viewBox="0 0 17 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M7.49559 0.22328L0.291504 6.95532L9.26978 16.5632L16.4739 9.83111L7.49559 0.22328Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M41 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89984 0.700195L16.8899 6.12018L12.8199 15.2602L0.839844 9.84021L4.89984 0.700195Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M42 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.399414 5.96027L8.49942 0.280273L16.1294 11.2302L7.86942 16.9202L0.399414 5.96027Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M43 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "17"}
//       height={height || "18"}
//       viewBox="0 0 17 18"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.659912 6.07996L8.91992 0.550049L16.3899 11.36L8.13992 17.04L0.659912 6.07996Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M44 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63989 0.359863L16.1099 11.1599L7.85986 16.8499L0.379883 5.88989L8.63989 0.359863Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M45 = ({ width, height }) => {
  return (
    <svg
      width={width || "14"}
      height={height || "17"}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.569336 3.70013L10.0193 0.870117L13.7393 13.4701L4.28932 16.3001L0.569336 3.70013Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M46 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "15"}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.399414 5.5802L12.6994 0.950195L16.2794 10.1002L3.97942 14.8802L0.399414 5.5802Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M47 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "14"}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.389648 3.79028L13.2896 0.510254L15.8197 10.1102L2.91965 13.3903L0.389648 3.79028Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M48 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "18"}
//       height={height || "17"}
//       viewBox="0 0 18 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.609863 8.04004L11.5599 0.560059L17.0999 8.82007L6.13989 16.29L0.609863 8.04004Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M49 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "18"}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.259766 10.8499L8.66976 0.669922L16.2998 6.82983L7.88976 17.1599L0.259766 10.8499Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M50 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "18"}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21945 17.4701L0.439453 11.1602L8.84946 0.830078L16.4794 7.14014L8.21945 17.4701Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M51 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39964 0.470215L16.1796 6.78027L7.76965 16.9602L0.139648 10.8003L8.39964 0.470215Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M52 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "13"}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.00976562 2.68005L12.8902 0L14.9702 9.75L1.93022 12.4301L-0.00976562 2.68005Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M53 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "12"}
//       height={height || "15"}
//       viewBox="0 0 12 15"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.810059 1.34009L10.7101 0.610107L11.7401 13.8L1.99005 14.53L0.810059 1.34009Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M54 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2167 0.00936982L0.564453 6.29053L8.94565 16.5012L16.5979 10.2201L8.2167 0.00936982Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M55 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "14"}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.969727 3.40002L13.8597 0.72998L15.9497 10.47L2.89972 13.15L0.969727 3.40002Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M56 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "14"}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.78929 -0.00976562L0.779297 9.4303L13.5093 13.3502L16.3793 3.91028L3.78929 -0.00976562Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M57 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19984 0.219727L16.5298 6.82971L7.66985 16.7097L0.339844 9.94971L9.19984 0.219727Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M58 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "14"}
//       height={height || "16"}
//       viewBox="0 0 14 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M10.1399 0.610107L13.1199 13.5L3.36989 15.74L0.389893 2.84009L10.1399 0.610107Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M59 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.49988 0.47998L14.5399 2.30005L13.1699 12.1899L0.129883 10.3701L1.49988 0.47998Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M60 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.97989 0.580078L15.0099 2.3999L13.6499 12.29L0.609863 10.47L1.97989 0.580078Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M61 = ({ width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "17"}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.910156 3.49988L10.5002 0.669922L14.0801 13.4199L4.63016 16.0999L0.910156 3.49988Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M62 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "17"}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.379883 8.56018L11.1899 0.930176L17.0199 9.04016L6.05988 16.6702L0.379883 8.56018Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M63 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72934 0.310059L16.2493 10.52L8.59933 16.8L0.0693359 6.58997L7.72934 0.310059Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M64 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "16"}
//       height={height || "13"}
//       viewBox="0 0 16 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M2.35983 0.159912L15.3998 2.28003L13.8899 12.02L0.849854 9.8999L2.35983 0.159912Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M65 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "13"}
//       viewBox="0 0 15 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.38995 0.939941L0.179932 10.6799L13.2199 12.3501L14.4299 2.45996L1.38995 0.939941Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M66 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "14"}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.589844 3.44995L13.4798 0.77002L15.4198 10.37L2.51985 13.04L0.589844 3.44995Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M67 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22972 0.879883L16.9197 6.88989L12.5497 15.7299L0.719727 9.70984L5.22972 0.879883Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M68 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "18"}
//       height={height || "17"}
//       viewBox="0 0 18 17"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M17.1199 8.66992L6.16989 16.1499L0.629883 7.88989L11.4399 0.419922L17.1199 8.66992Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
// export const M69 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "11"}
//       height={height || "14"}
//       viewBox="0 0 11 14"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M0.480072 0.51001L10.3701 0.669922L10.2001 13.8701L0.310059 13.7L0.480072 0.51001Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const M70 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "14"}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.669922 3.39001L13.7099 0.709961L15.6499 10.46L2.74991 13.14L0.669922 3.39001Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M71 = ({ width, height }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "13"}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.459961 3.1897L13.36 0.509766L15.29 10.2598L2.39996 12.9397L0.459961 3.1897Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M72 = ({ width, height }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "18"}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.48962 0.650391L16.3196 10.5604L8.81964 17.1404L0.139648 7.23035L7.48962 0.650391Z"
        fill="#857700"
      />
    </svg>
  );
};
export const M73 = ({ width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40982 0.959961L17.2898 9.81995L10.6798 17.15L0.799805 8.43994L7.40982 0.959961Z"
        fill="#857700"
      />
    </svg>
  );
};
// export const M77 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "15"}
//       height={height || "11"}
//       viewBox="0 0 15 11"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.09995 0.179932L14.2999 0.209961L14.2799 10.25L0.939941 10.0801L1.09995 0.179932Z"
//         fill="#877700"
//       />
//     </svg>
//   );
// };
export const D01 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "21"}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.90993 0.22998L21.6799 3.15002L18.9399 20.37L0.169922 17.45L2.90993 0.22998Z"
        fill="#4D5943"
      />
      <path
        d="M12.7805 16.84C16.2599 16.84 19.0805 14.0194 19.0805 10.54C19.0805 7.06058 16.2599 4.23999 12.7805 4.23999C9.30108 4.23999 6.48047 7.06058 6.48047 10.54C6.48047 14.0194 9.30108 16.84 12.7805 16.84Z"
        fill="white"
      />
    </svg>
  );
};
export const D02 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "21"}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.78055 0.550049L21.5506 3.44006L18.8306 20.66L0.0605469 17.7801L2.78055 0.550049Z"
        fill="#4D5943"
      />
      <path
        d="M13.1701 17.4901C16.6495 17.4901 19.4701 14.6695 19.4701 11.1901C19.4701 7.71073 16.6495 4.89014 13.1701 4.89014C9.69073 4.89014 6.87012 7.71073 6.87012 11.1901C6.87012 14.6695 9.69073 17.4901 13.1701 17.4901Z"
        fill="white"
      />
    </svg>
  );
};
export const D03 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "21"}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6805 3.4201L19.0205 20.6601L0.230469 17.8401L2.89047 0.600098L21.6805 3.4201Z"
        fill="#4D5943"
      />
      <path
        d="M13.34 17.36C16.8194 17.36 19.64 14.5394 19.64 11.06C19.64 7.5806 16.8194 4.76001 13.34 4.76001C9.86065 4.76001 7.04004 7.5806 7.04004 11.06C7.04004 14.5394 9.86065 17.36 13.34 17.36Z"
        fill="white"
      />
    </svg>
  );
};
export const D04 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "21"}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7499 20.5601L0.919922 18.0801L3.27992 0.800049L22.1099 3.28009L19.7499 20.5601Z"
        fill="#4D5943"
      />
      <path
        d="M9.13986 16.7299C12.6193 16.7299 15.4398 13.9093 15.4398 10.4299C15.4398 6.95048 12.6193 4.12988 9.13986 4.12988C5.66047 4.12988 2.83984 6.95048 2.83984 10.4299C2.83984 13.9093 5.66047 16.7299 9.13986 16.7299Z"
        fill="white"
      />
    </svg>
  );
};
export const D05 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5706 10.7001L14.3005 25.9901L0.310547 15.5901L11.5805 0.300049L25.5706 10.7001Z"
        fill="#4D5943"
      />
      <path
        d="M11.5002 21.36C14.9796 21.36 17.8002 18.5394 17.8002 15.06C17.8002 11.5806 14.9796 8.76001 11.5002 8.76001C8.02079 8.76001 5.2002 11.5806 5.2002 15.06C5.2002 18.5394 8.02079 21.36 11.5002 21.36Z"
        fill="white"
      />
    </svg>
  );
};
export const D06 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "21"}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9405 20.4299L0.520508 19.6699L1.27051 0.689941L18.6905 1.44995L17.9405 20.4299Z"
        fill="#4D5943"
      />
      <path
        d="M9.67987 19.4401C13.1593 19.4401 15.9799 16.6195 15.9799 13.1401C15.9799 9.66068 13.1593 6.84009 9.67987 6.84009C6.20048 6.84009 3.37988 9.66068 3.37988 13.1401C3.37988 16.6195 6.20048 19.4401 9.67987 19.4401Z"
        fill="white"
      />
    </svg>
  );
};
export const D07 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2202 7.58002L16.3203 24.85L0.490234 17.52L8.40024 0.25L24.2202 7.58002Z"
        fill="#4D5943"
      />
      <path
        d="M11.3 21.28C14.7794 21.28 17.6 18.4593 17.6 14.9799C17.6 11.5005 14.7794 8.67993 11.3 8.67993C7.82059 8.67993 5 11.5005 5 14.9799C5 18.4593 7.82059 21.28 11.3 21.28Z"
        fill="white"
      />
    </svg>
  );
};
export const D08 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.94 26.16L0.459961 13.03L14.71 0.469971L26.19 13.6L11.94 26.16Z"
        fill="#4D5943"
      />
      <path
        d="M11.2502 21.2899C14.7296 21.2899 17.5502 18.4693 17.5502 14.9899C17.5502 11.5105 14.7296 8.68994 11.2502 8.68994C7.77079 8.68994 4.9502 11.5105 4.9502 14.9899C4.9502 18.4693 7.77079 21.2899 11.2502 21.2899Z"
        fill="white"
      />
    </svg>
  );
};
export const D09 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "25"}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.370117 9.75995L16.9101 0.429932L25.4201 15.65L8.87012 24.9799L0.370117 9.75995Z"
        fill="#4D5943"
      />
      <path
        d="M10.6301 20.2201C14.1095 20.2201 16.9301 17.3995 16.9301 13.9201C16.9301 10.4407 14.1095 7.62012 10.6301 7.62012C7.1507 7.62012 4.33008 10.4407 4.33008 13.9201C4.33008 17.3995 7.1507 20.2201 10.6301 20.2201Z"
        fill="white"
      />
    </svg>
  );
};
export const D10 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "26"}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1903 15.0801L14.5803 0.0400391L0.820312 10.7501L12.4303 25.79L26.1903 15.0801Z"
        fill="#4D5943"
      />
      <path
        d="M14.9299 20.9301C18.4093 20.9301 21.2299 18.1095 21.2299 14.6301C21.2299 11.1507 18.4093 8.33008 14.9299 8.33008C11.4505 8.33008 8.62988 11.1507 8.62988 14.6301C8.62988 18.1095 11.4505 20.9301 14.9299 20.9301Z"
        fill="white"
      />
    </svg>
  );
};
export const D11 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79027 25.4501L24.3503 17.5801L15.8503 0.600098L0.280273 8.47009L8.79027 25.4501Z"
        fill="#4D5943"
      />
      <path
        d="M13.2307 21.37C16.7101 21.37 19.5307 18.5494 19.5307 15.07C19.5307 11.5906 16.7101 8.77002 13.2307 8.77002C9.75127 8.77002 6.93066 11.5906 6.93066 15.07C6.93066 18.5494 9.75127 21.37 13.2307 21.37Z"
        fill="white"
      />
    </svg>
  );
};
export const D12 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "21"}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9599 19.5301L17.9998 0.560059L0.589844 1.52008L1.54985 20.4901L18.9599 19.5301Z"
        fill="#4D5943"
      />
      <path
        d="M9.77071 19.2299C13.2501 19.2299 16.0707 16.4093 16.0707 12.9299C16.0707 9.45048 13.2501 6.62988 9.77071 6.62988C6.29131 6.62988 3.4707 9.45048 3.4707 12.9299C3.4707 16.4093 6.29131 19.2299 9.77071 19.2299Z"
        fill="white"
      />
    </svg>
  );
};
export const D13 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "19"}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0201 18.76L0.0400391 17.96L0.850052 0.540039L19.83 1.33997L19.0201 18.76Z"
        fill="#4D5943"
      />
      <path
        d="M7.96016 15.54C11.4396 15.54 14.2601 12.7194 14.2601 9.23999C14.2601 5.7606 11.4396 2.93994 7.96016 2.93994C4.48077 2.93994 1.66016 5.7606 1.66016 9.23999C1.66016 12.7194 4.48077 15.54 7.96016 15.54Z"
        fill="white"
      />
    </svg>
  );
};
export const D14 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "23"}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.99 23.01L0.75 17.71L5.67999 0.97998L23.92 6.28003L18.99 23.01Z"
        fill="#4D5943"
      />
      <path
        d="M10.3205 17.6901C13.7999 17.6901 16.6205 14.8695 16.6205 11.3901C16.6205 7.91068 13.7999 5.09009 10.3205 5.09009C6.84112 5.09009 4.02051 7.91068 4.02051 11.3901C4.02051 14.8695 6.84112 17.6901 10.3205 17.6901Z"
        fill="white"
      />
    </svg>
  );
};
export const D15 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.570312 9.83997L15.4203 0.699951L25.4403 16.83L10.6003 25.9799L0.570312 9.83997Z"
        fill="#4D5943"
      />
      <path
        d="M11.6506 17.4901C15.13 17.4901 17.9506 14.6695 17.9506 11.1901C17.9506 7.71073 15.13 4.89014 11.6506 4.89014C8.17118 4.89014 5.35059 7.71073 5.35059 11.1901C5.35059 14.6695 8.17118 17.4901 11.6506 17.4901Z"
        fill="white"
      />
    </svg>
  );
};
export const D16 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "26"}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9906 25.7799L0.310547 12.5999L12.4606 0.0898438L26.1405 13.2699L13.9906 25.7799Z"
        fill="#4D5943"
      />
      <path
        d="M14.9006 20.89C18.38 20.89 21.2006 18.0694 21.2006 14.59C21.2006 11.1106 18.38 8.29004 14.9006 8.29004C11.4212 8.29004 8.60059 11.1106 8.60059 14.59C8.60059 18.0694 11.4212 20.89 14.9006 20.89Z"
        fill="white"
      />
    </svg>
  );
};
export const D17 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9202 24.3298L0.410156 16.9598L7.25015 0.919922L24.7502 8.28992L17.9202 24.3298Z"
        fill="#4D5943"
      />
      <path
        d="M14.7404 20.4199C18.2198 20.4199 21.0404 17.5993 21.0404 14.1199C21.0404 10.6405 18.2198 7.81982 14.7404 7.81982C11.261 7.81982 8.44043 10.6405 8.44043 14.1199C8.44043 17.5993 11.261 20.4199 14.7404 20.4199Z"
        fill="white"
      />
    </svg>
  );
};
export const D18 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "19"}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9408 0.120117L20.2107 17.5101L1.27075 18.9601L0.0107422 1.57013L18.9408 0.120117Z"
        fill="#4D5943"
      />
      <path
        d="M12.3205 15.91C15.7999 15.91 18.6205 13.0894 18.6205 9.61005C18.6205 6.13065 15.7999 3.31006 12.3205 3.31006C8.84113 3.31006 6.02051 6.13065 6.02051 9.61005C6.02051 13.0894 8.84113 15.91 12.3205 15.91Z"
        fill="white"
      />
    </svg>
  );
};
export const D19 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "20"}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.55 19.95L0.709961 17.56L2.97998 0.27002L21.82 2.65997L19.55 19.95Z"
        fill="#4D5943"
      />
      <path
        d="M8.94061 15.9501C12.42 15.9501 15.2406 13.1295 15.2406 9.65015C15.2406 6.17075 12.42 3.3501 8.94061 3.3501C5.46122 3.3501 2.64062 6.17075 2.64062 9.65015C2.64062 13.1295 5.46122 15.9501 8.94061 15.9501Z"
        fill="white"
      />
    </svg>
  );
};
export const D20 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "26"}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1898 11.7L16.0498 25.8799L0.549805 14.89L10.6998 0.709961L26.1898 11.7Z"
        fill="#4D5943"
      />
      <path
        d="M11.4699 18.37C14.9493 18.37 17.7699 15.5493 17.7699 12.0699C17.7699 8.59055 14.9493 5.77002 11.4699 5.77002C7.99052 5.77002 5.16992 8.59055 5.16992 12.0699C5.16992 15.5493 7.99052 18.37 11.4699 18.37Z"
        fill="white"
      />
    </svg>
  );
};
export const D21 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6404 0.680176L26.0604 15.0502L12.8204 26.4001L0.400391 12.0302L13.6404 0.680176Z"
        fill="#4D5943"
      />
      <path
        d="M11.7903 18.2601C15.2696 18.2601 18.0902 15.4395 18.0902 11.9601C18.0902 8.48069 15.2696 5.66016 11.7903 5.66016C8.31086 5.66016 5.49023 8.48069 5.49023 11.9601C5.49023 15.4395 8.31086 18.2601 11.7903 18.2601Z"
        fill="white"
      />
    </svg>
  );
};
export const D22 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2498 0.560059L26.3998 15.16L12.9598 26.26L0.799805 11.66L14.2498 0.560059Z"
        fill="#4D5943"
      />
      <path
        d="M12.2101 18.0699C15.6895 18.0699 18.5102 15.2494 18.5102 11.77C18.5102 8.29063 15.6895 5.46997 12.2101 5.46997C8.73075 5.46997 5.91016 8.29063 5.91016 11.77C5.91016 15.2494 8.73075 18.0699 12.2101 18.0699Z"
        fill="white"
      />
    </svg>
  );
};
export const D23 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.540039 11.9901L13.99 0.890137L26.14 15.4901L12.69 26.5901L0.540039 11.9901Z"
        fill="#4D5943"
      />
      <path
        d="M12.0803 18.1898C15.5597 18.1898 18.3803 15.3693 18.3803 11.8899C18.3803 8.4105 15.5597 5.58984 12.0803 5.58984C8.60087 5.58984 5.78027 8.4105 5.78027 11.8899C5.78027 15.3693 8.60087 18.1898 12.0803 18.1898Z"
        fill="white"
      />
    </svg>
  );
};
// export const D24 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "35"}
//       height={height || "36"}
//       viewBox="0 0 35 36"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M19.8263 0.924805L34.737 22.0613L15.2877 35.6642L0.376953 14.5405L19.8263 0.924805Z"
//         fill="#4D5943"
//       />
//       <path
//         d="M15.6671 25.1362C20.4058 25.1362 24.2473 21.2946 24.2473 16.5559C24.2473 11.8172 20.4058 7.97559 15.6671 7.97559C10.9284 7.97559 7.08691 11.8172 7.08691 16.5559C7.08691 21.2946 10.9284 25.1362 15.6671 25.1362Z"
//         fill="white"
//       />
//     </svg>
//   );
// };
export const D25 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "22"}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7007 2.37L18.2607 21.2L0.970703 18.89L3.4207 0.0500488L20.7007 2.37Z"
        fill="#4D5943"
      />
      <path
        d="M11.0305 14.2499C14.5099 14.2499 17.3305 11.4292 17.3305 7.94983C17.3305 4.47044 14.5099 1.6499 11.0305 1.6499C7.55107 1.6499 4.73047 4.47044 4.73047 7.94983C4.73047 11.4292 7.55107 14.2499 11.0305 14.2499Z"
        fill="white"
      />
    </svg>
  );
};
export const D26 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "22"}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9402 2.68018L18.0902 21.5901L0.740234 19.8102L2.60023 0.910156L19.9402 2.68018Z"
        fill="#4D5943"
      />
      <path
        d="M10.7404 14.85C14.2198 14.85 17.0404 12.0294 17.0404 8.55005C17.0404 5.07065 14.2198 2.25 10.7404 2.25C7.26104 2.25 4.44043 5.07065 4.44043 8.55005C4.44043 12.0294 7.26104 14.85 10.7404 14.85Z"
        fill="white"
      />
    </svg>
  );
};
export const D27 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0604 20.6702L0.69043 19.1001L2.32043 0.180176L19.6904 1.75012L18.0604 20.6702Z"
        fill="#4D5943"
      />
      <path
        d="M10.4104 14.0601C13.8897 14.0601 16.7104 11.2394 16.7104 7.76001C16.7104 4.28062 13.8897 1.45996 10.4104 1.45996C6.93096 1.45996 4.11035 4.28062 4.11035 7.76001C4.11035 11.2394 6.93096 14.0601 10.4104 14.0601Z"
        fill="white"
      />
    </svg>
  );
};
export const D28 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "20"}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6204 19.95L0.69043 18.33L2.26044 0.959961L21.1804 2.57996L19.6204 19.95Z"
        fill="#4D5943"
      />
      <path
        d="M13.2502 16.84C16.7296 16.84 19.5502 14.0194 19.5502 10.54C19.5502 7.06065 16.7296 4.23999 13.2502 4.23999C9.77079 4.23999 6.9502 7.06065 6.9502 10.54C6.9502 14.0194 9.77079 16.84 13.2502 16.84Z"
        fill="white"
      />
    </svg>
  );
};
export const D29 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "21"}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9404 3.63L19.1704 20.85L0.400391 17.9L3.18039 0.689941L21.9404 3.63Z"
        fill="#4D5943"
      />
      <path
        d="M9.16037 16.59C12.6398 16.59 15.4604 13.7694 15.4604 10.29C15.4604 6.81065 12.6398 3.98999 9.16037 3.98999C5.68098 3.98999 2.86035 6.81065 2.86035 10.29C2.86035 13.7694 5.68098 16.59 9.16037 16.59Z"
        fill="white"
      />
    </svg>
  );
};
export const D30 = ({ width, height }) => {
  return (
    <svg
      width={width || "23"}
      height={height || "21"}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35995 0.280029L22.18 2.81995L19.78 20.09L0.959961 17.55L3.35995 0.280029Z"
        fill="#4D5943"
      />
      <path
        d="M9.29999 16.2299C12.7794 16.2299 15.6 13.4093 15.6 9.92993C15.6 6.45054 12.7794 3.62988 9.29999 3.62988C5.82059 3.62988 3 6.45054 3 9.92993C3 13.4093 5.82059 16.2299 9.29999 16.2299Z"
        fill="white"
      />
    </svg>
  );
};
export const D31 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0604 0.72998L26.2704 16.05L12.1604 26.3L0.94043 10.97L15.0604 0.72998Z"
        fill="#4D5943"
      />
      <path
        d="M12.2307 17.85C15.7101 17.85 18.5307 15.0294 18.5307 11.55C18.5307 8.07065 15.7101 5.25 12.2307 5.25C8.75129 5.25 5.93066 8.07065 5.93066 11.55C5.93066 15.0294 8.75129 17.85 12.2307 17.85Z"
        fill="white"
      />
    </svg>
  );
};
export const D32 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "27"}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.280273 10.65L14.5003 0.560059L25.5603 16L11.3303 26.09L0.280273 10.65Z"
        fill="#4D5943"
      />
      <path
        d="M11.6906 18.0601C15.17 18.0601 17.9906 15.2394 17.9906 11.76C17.9906 8.28062 15.17 5.45996 11.6906 5.45996C8.21122 5.45996 5.39062 8.28062 5.39062 11.76C5.39062 15.2394 8.21122 18.0601 11.6906 18.0601Z"
        fill="white"
      />
    </svg>
  );
};
export const D33 = ({ width, height }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "20"}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8902 0.319824L18.3302 19.3099L0.900177 19.6399L0.450195 0.649902L17.8902 0.319824Z"
        fill="#4D5943"
      />
      <path
        d="M9.32053 14.2401C12.7999 14.2401 15.6205 11.4195 15.6205 7.94006C15.6205 4.46067 12.7999 1.64014 9.32053 1.64014C5.84113 1.64014 3.02051 4.46067 3.02051 7.94006C3.02051 11.4195 5.84113 14.2401 9.32053 14.2401Z"
        fill="white"
      />
    </svg>
  );
};
export const D34 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "22"}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.52069 0.449951L23.1307 4.22998L19.5807 21.3101L0.970703 17.52L4.52069 0.449951Z"
        fill="#4D5943"
      />
      <path
        d="M9.83026 16.8101C13.3097 16.8101 16.1303 13.9894 16.1303 10.51C16.1303 7.03062 13.3097 4.20996 9.83026 4.20996C6.35087 4.20996 3.53027 7.03062 3.53027 10.51C3.53027 13.9894 6.35087 16.8101 9.83026 16.8101Z"
        fill="white"
      />
    </svg>
  );
};
export const D35 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.490234 17.0001L7.15024 0.890137L24.7402 8.07007L18.0802 24.1802L0.490234 17.0001Z"
        fill="#4D5943"
      />
      <path
        d="M10.3605 18.2001C13.8399 18.2001 16.6606 15.3795 16.6606 11.9001C16.6606 8.42075 13.8399 5.6001 10.3605 5.6001C6.88114 5.6001 4.06055 8.42075 4.06055 11.9001C4.06055 15.3795 6.88114 18.2001 10.3605 18.2001Z"
        fill="white"
      />
    </svg>
  );
};
export const D36 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "27"}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5403 0.930176L25.6003 16.3701L11.3803 26.4601L0.320312 11.0201L14.5403 0.930176Z"
        fill="#4D5943"
      />
      <path
        d="M11.2903 18.2299C14.7696 18.2299 17.5902 15.4093 17.5902 11.9299C17.5902 8.45054 14.7696 5.62988 11.2903 5.62988C7.81086 5.62988 4.99023 8.45054 4.99023 11.9299C4.99023 15.4093 7.81086 18.2299 11.2903 18.2299Z"
        fill="white"
      />
    </svg>
  );
};
export const D37 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8699 0.649902L25.6399 16.9398L10.6499 25.8499L0.879883 9.54993L15.8699 0.649902Z"
        fill="#4D5943"
      />
      <path
        d="M12.0002 17.87C15.4796 17.87 18.3002 15.0493 18.3002 11.5699C18.3002 8.09055 15.4796 5.27002 12.0002 5.27002C8.52079 5.27002 5.7002 8.09055 5.7002 11.5699C5.7002 15.0493 8.52079 17.87 12.0002 17.87Z"
        fill="white"
      />
    </svg>
  );
};
export const D38 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.870117 9.15002L15.8601 0.25L25.6201 16.54L10.6301 25.4399L0.870117 9.15002Z"
        fill="#4D5943"
      />
      <path
        d="M12.0305 17.33C15.5099 17.33 18.3304 14.5094 18.3304 11.03C18.3304 7.55064 15.5099 4.72998 12.0305 4.72998C8.55106 4.72998 5.73047 7.55064 5.73047 11.03C5.73047 14.5094 8.55106 17.33 12.0305 17.33Z"
        fill="white"
      />
    </svg>
  );
};
export const D39 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3904 0.189941L25.1604 16.48L10.1704 25.38L0.400391 9.08997L15.3904 0.189941Z"
        fill="#4D5943"
      />
      <path
        d="M11.8498 17.2699C15.3292 17.2699 18.1498 14.4492 18.1498 10.9698C18.1498 7.49045 15.3292 4.66992 11.8498 4.66992C8.37043 4.66992 5.5498 7.49045 5.5498 10.9698C5.5498 14.4492 8.37043 17.2699 11.8498 17.2699Z"
        fill="white"
      />
    </svg>
  );
};
export const D40 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5199 16.7499L15.9499 0.339844L0.919922 9.17993L10.4799 25.5898L25.5199 16.7499Z"
        fill="#4D5943"
      />
      <path
        d="M14.2004 20.8199C17.6798 20.8199 20.5004 17.9994 20.5004 14.52C20.5004 11.0406 17.6798 8.21997 14.2004 8.21997C10.721 8.21997 7.90039 11.0406 7.90039 14.52C7.90039 17.9994 10.721 20.8199 14.2004 20.8199Z"
        fill="white"
      />
    </svg>
  );
};
export const D41 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.64984 26.01L24.6798 17.17L15.1198 0.75L0.0898438 9.58997L9.64984 26.01Z"
        fill="#4D5943"
      />
      <path
        d="M13.2707 21.11C16.7501 21.11 19.5707 18.2895 19.5707 14.8101C19.5707 11.3307 16.7501 8.51001 13.2707 8.51001C9.79131 8.51001 6.9707 11.3307 6.9707 14.8101C6.9707 18.2895 9.79131 21.11 13.2707 21.11Z"
        fill="white"
      />
    </svg>
  );
};
export const D42 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4298 16.46L15.8698 0.0500488L0.839844 8.89001L10.3998 25.3L25.4298 16.46Z"
        fill="#4D5943"
      />
      <path
        d="M14.2404 20.4302C17.7198 20.4302 20.5404 17.6095 20.5404 14.1301C20.5404 10.6507 17.7198 7.83008 14.2404 7.83008C10.761 7.83008 7.94043 10.6507 7.94043 14.1301C7.94043 17.6095 10.761 20.4302 14.2404 20.4302Z"
        fill="white"
      />
    </svg>
  );
};
export const D43 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2703 0.589844L25.0403 16.8799L10.0403 25.7899L0.280273 9.48987L15.2703 0.589844Z"
        fill="#4D5943"
      />
      <path
        d="M11.6604 17.6802C15.1397 17.6802 17.9604 14.8595 17.9604 11.3801C17.9604 7.90073 15.1397 5.08008 11.6604 5.08008C8.18096 5.08008 5.36035 7.90073 5.36035 11.3801C5.36035 14.8595 8.18096 17.6802 11.6604 17.6802Z"
        fill="white"
      />
    </svg>
  );
};
export const D44 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.629883 9.48987L15.6199 0.589844L25.3799 16.8799L10.3899 25.7899L0.629883 9.48987Z"
        fill="#4D5943"
      />
      <path
        d="M12.0099 17.9799C15.4893 17.9799 18.31 15.1593 18.31 11.6799C18.31 8.20054 15.4893 5.37988 12.0099 5.37988C8.53055 5.37988 5.70996 8.20054 5.70996 11.6799C5.70996 15.1593 8.53055 17.9799 12.0099 17.9799Z"
        fill="white"
      />
    </svg>
  );
};
export const D45 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1501 0.26001L24.9202 16.55L9.92015 25.46L0.160156 9.17004L15.1501 0.26001Z"
        fill="#4D5943"
      />
      <path
        d="M11.2404 17.3C14.7198 17.3 17.5404 14.4794 17.5404 11C17.5404 7.52061 14.7198 4.69995 11.2404 4.69995C7.76104 4.69995 4.94043 7.52061 4.94043 11C4.94043 14.4794 7.76104 17.3 11.2404 17.3Z"
        fill="white"
      />
    </svg>
  );
};

export const D46 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4001 0.439941L25.1702 16.73L10.1801 25.64L0.410156 9.34998L15.4001 0.439941Z"
        fill="#4D5943"
      />
      <path
        d="M11.5803 17.4398C15.0597 17.4398 17.8803 14.6193 17.8803 11.1399C17.8803 7.6605 15.0597 4.83984 11.5803 4.83984C8.10088 4.83984 5.28027 7.6605 5.28027 11.1399C5.28027 14.6193 8.10088 17.4398 11.5803 17.4398Z"
        fill="white"
      />
    </svg>
  );
};
// export const D47 = ({ width, height }) => {
//   return (
//     <svg
//       width={width || "27"}
//       height={height || "27"}
//       viewBox="0 0 27 27"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M16.5402 0.73999L26.7102 17.7L11.1002 26.96L0.940186 10.01L16.5402 0.73999Z"
//         fill="#4D5943"
//       />
//       <path
//         d="M12.4401 18.4797C16.0631 18.4797 19.0001 15.5427 19.0001 11.9197C19.0001 8.29669 16.0631 5.35986 12.4401 5.35986C8.81714 5.35986 5.88013 8.29669 5.88013 11.9197C5.88013 15.5427 8.81714 18.4797 12.4401 18.4797Z"
//         fill="white"
//       />
//     </svg>
//   );
// };
export const D48 = ({ width, height }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8006 0.72998L25.5706 17.02L10.5806 25.9301L0.810547 9.64001L15.8006 0.72998Z"
        fill="#4D5943"
      />
      <path
        d="M11.8605 17.7699C15.3399 17.7699 18.1606 14.9492 18.1606 11.4698C18.1606 7.99045 15.3399 5.16992 11.8605 5.16992C8.38116 5.16992 5.56055 7.99045 5.56055 11.4698C5.56055 14.9492 8.38116 17.7699 11.8605 17.7699Z"
        fill="white"
      />
    </svg>
  );
};

export const D49 = ({ width, height }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0498047 8.96985L15.0498 0.0698242L24.8098 16.3599L9.81981 25.2599L0.0498047 8.96985Z"
        fill="#4D5943"
      />
      <path
        d="M11.4904 17.2699C14.9698 17.2699 17.7904 14.4492 17.7904 10.9698C17.7904 7.49045 14.9698 4.66992 11.4904 4.66992C8.01104 4.66992 5.19043 7.49045 5.19043 10.9698C5.19043 14.4492 8.01104 17.2699 11.4904 17.2699Z"
        fill="white"
      />
    </svg>
  );
};

export const D50 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "23"}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8707 16.66L5.96071 22.99L0.220703 6.52002L18.1307 0.199951L23.8707 16.66Z"
        fill="#4D5943"
      />
      <path
        d="M10.0607 18.2499C13.5401 18.2499 16.3607 15.4292 16.3607 11.9498C16.3607 8.47044 13.5401 5.6499 10.0607 5.6499C6.58135 5.6499 3.76074 8.47044 3.76074 11.9498C3.76074 15.4292 6.58135 18.2499 10.0607 18.2499Z"
        fill="white"
      />
    </svg>
  );
};

export const D51 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "22"}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3205 0.310059L20.4705 19.1801L3.14047 21.0901L0.980469 2.22003L18.3205 0.310059Z"
        fill="#4D5943"
      />
      <path
        d="M10.1301 15.2099C13.6095 15.2099 16.4301 12.3892 16.4301 8.90985C16.4301 5.43046 13.6095 2.60986 10.1301 2.60986C6.65069 2.60986 3.83008 5.43046 3.83008 8.90985C3.83008 12.3892 6.65069 15.2099 10.1301 15.2099Z"
        fill="white"
      />
    </svg>
  );
};

export const D52 = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "23"}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3404 16.9401L5.06039 22.0901L0.400391 5.28015L18.6904 0.140137L23.3404 16.9401Z"
        fill="#4D5943"
      />
      <path
        d="M13.5402 16.53C17.0196 16.53 19.8402 13.7093 19.8402 10.2299C19.8402 6.75053 17.0196 3.92993 13.5402 3.92993C10.0608 3.92993 7.24023 6.75053 7.24023 10.2299C7.24023 13.7093 10.0608 16.53 13.5402 16.53Z"
        fill="white"
      />
    </svg>
  );
};

export const D53 = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "18"}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.560547 0.359985L19.5505 0.27002L19.5706 17.7L0.570557 17.8L0.560547 0.359985Z"
        fill="#4D5943"
      />
      <path
        d="M8.21991 15.7401C11.6993 15.7401 14.5199 12.9195 14.5199 9.44012C14.5199 5.96073 11.6993 3.14014 8.21991 3.14014C4.74052 3.14014 1.91992 5.96073 1.91992 9.44012C1.91992 12.9195 4.74052 15.7401 8.21991 15.7401Z"
        fill="white"
      />
    </svg>
  );
};
export const D54 = ({ width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "21"}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84985 0.839844L21.6599 3.49988L19.1399 20.7599L0.339844 18.0999L2.84985 0.839844Z"
        fill="#4D5943"
      />
      <path
        d="M9.11053 17.2299C12.5899 17.2299 15.4106 14.4093 15.4106 10.9299C15.4106 7.45054 12.5899 4.62988 9.11053 4.62988C5.63114 4.62988 2.81055 7.45054 2.81055 10.9299C2.81055 14.4093 5.63114 17.2299 9.11053 17.2299Z"
        fill="white"
      />
    </svg>
  );
};
export const D55 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "22"}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4805 2.52014L18.0105 21.3501L0.730469 19.0101L3.20047 0.180176L20.4805 2.52014Z"
        fill="#4D5943"
      />
      <path
        d="M10.4504 15.1802C13.9298 15.1802 16.7504 12.3595 16.7504 8.88013C16.7504 5.40073 13.9298 2.58008 10.4504 2.58008C6.97101 2.58008 4.15039 5.40073 4.15039 8.88013C4.15039 12.3595 6.97101 15.1802 10.4504 15.1802Z"
        fill="white"
      />
    </svg>
  );
};
export const D56 = ({ width, height }) => {
  return (
    <svg
      width={width || "21"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.20004 0.97998L20.16 2.16003L19 19.5601L0.0400391 18.37L1.20004 0.97998Z"
        fill="#4D5943"
      />
      <path
        d="M8.23068 16.84C11.7101 16.84 14.5307 14.0194 14.5307 10.54C14.5307 7.06065 11.7101 4.23999 8.23068 4.23999C4.75129 4.23999 1.93066 7.06065 1.93066 10.54C1.93066 14.0194 4.75129 16.84 8.23068 16.84Z"
        fill="white"
      />
    </svg>
  );
};
export const D57 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5506 0.75L26.0606 15.86L12.1506 26.37L0.640625 11.26L14.5506 0.75Z"
        fill="#4D5943"
      />
      <path
        d="M11.9201 18.63C15.3995 18.63 18.2201 15.8094 18.2201 12.33C18.2201 8.85056 15.3995 6.03003 11.9201 6.03003C8.44074 6.03003 5.62012 8.85056 5.62012 12.33C5.62012 15.8094 8.44074 18.63 11.9201 18.63Z"
        fill="white"
      />
    </svg>
  );
};
export const D58 = ({ width, height }) => {
  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5506 0.75L26.0606 15.86L12.1506 26.37L0.640625 11.26L14.5506 0.75Z"
        fill="#4D5943"
      />
      <path
        d="M11.9201 18.63C15.3995 18.63 18.2201 15.8094 18.2201 12.33C18.2201 8.85056 15.3995 6.03003 11.9201 6.03003C8.44074 6.03003 5.62012 8.85056 5.62012 12.33C5.62012 15.8094 8.44074 18.63 11.9201 18.63Z"
        fill="white"
      />
    </svg>
  );
};
