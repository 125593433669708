import { IS_STAGING } from "../services/firebase";

// const key = IS_STAGING
//   ? process.env.REACT_APP_HUBSPOT_STAGING //staging
//   : process.env.REACT_APP_HUBSPOT_PRODUCTION; //production

export const headersKey = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${
    IS_STAGING
      ? process.env.REACT_APP_HUBSPOT_STAGING_TOKEN
      : process.env.REACT_APP_HUBSPOT_PRODUCTION_TOKEN
  }`,
};

const proxy = "https://cors.stage.eugeniuses.com/";
const endPoint = proxy + process.env.REACT_APP_HUBSPOT_URL;

export const MAP_DATA = `${endPoint}/deals/v1/deal/paged?properties=statut_des_promesses_d_achat&properties=identification_du_lot_ph&properties=depot_requis&properties=frais_d_infrastructure&properties=prix_total_sans_infrastructure&properties=tarif_fixe_pour_ph4&properties=prix_au_pi2&properties=nombre_de_pi2&properties=depot_necessaire&properties=description&properties=dealname&properties=amount&properties=pipeline&properties=dealstage&limit=250`;

export const GET_CONTACT_BY_EMAIL = (email) => {
  return `${endPoint}/contacts/v1/contact/email/${email}/profile`;
};
export const GET_CUSTOM_OBJECT_BY_VID = (vid) => {
  return `${endPoint}/crm/v3/objects/contacts/${vid}/associations/p_acc_s_aux_portails`;
};
export const GET_CUSTOM_OBJECT_BY_DEAL = (dealId) => {
  return `${endPoint}/crm/v3/objects/deal/${dealId}/associations/favoris`;
};

export const UPDATE_PASSWORD = (customObjectId) => {
  return `${endPoint}/crm/v3/objects/p_acc_s_aux_portails/${customObjectId}`;
};

export const CREATE_CONTACT = `${endPoint}/contacts/v1/contact`;

export const FAVOURITE_GET = (offset) =>
  `${endPoint}/crm/v3/objects/favoris?properties=favoris&limit=100&after=${offset}`;

export const CREATE_CUSTOME_OBJECT = `${endPoint}/crm/v3/objects/p_acc_s_aux_portails`;

export const ACC_S_AUX_PORTAILS_ASSOCIATE = (customObjectId, contactVid) => {
  return `${endPoint}/crm/v3/objects/p_acc_s_aux_portails/${customObjectId}/associations/contacts/${contactVid}/31`;
};

export const CUSTOM_OBJECT_ASSOCIATE = (customObjectId, contactVid) => {
  return `${endPoint}/crm/v3/objects/favoris/${customObjectId}/associations/contacts/${contactVid}/89`;
};
export const CUSTOM_OBJECT_ASSOCIATE_DELETE = (customObjectId, contactVid) => {
  return `${endPoint}/crm/v3/objects/favoris/${customObjectId}/associations/contacts/${contactVid}/89`;
};
export const UPDATE_CONTACT = (contactId) => {
  return `${endPoint}/contacts/v1/contact/vid/${contactId}/profile`;
};
export const ADD_CONTACT_TO_LIST = `${endPoint}/contacts/v1/lists/603/add`;
export const REMOVE_CONTACT_TO_LIST = `${endPoint}/contacts/v1/lists/603/remove`;

export const CREATE_DEAL = `${endPoint}/deals/v1/deal`;

export const UPDATE_DEAL = (dealId) => `${endPoint}/deals/v1/deal/${dealId}`;

export const GET_COMPANIES = `${endPoint}/companies/v2/companies/paged?properties=name&properties=website&properties=email&properties=phone`;

export const CREATE_COMPANY = `${endPoint}/companies/v2/companies`;

export const ADD_CONTACT_TO_COMPANY = (comapany, contact) => {
  return `${endPoint}/companies/v2/companies/${comapany}/contacts/${contact}`;
};

export const PIPELINE_LIST = `${endPoint}/crm-pipelines/v1/pipelines/deals`;

export const OWNER_LIST = `${endPoint}/owners/v2/owners`;

export const CREATE_ENGAGEMENT = `${endPoint}/engagements/v1/engagements?includeAssociations=true&limit=20&properties=dealname&properties=type_de_pa`;

export const CRM_ASSOCIATIONS_BATCH = `${endPoint}/crm-associations/v1/associations/create-batch`;

export const GET_CONTACT_BY_ID = (id) => {
  return `${endPoint}/contacts/v1/contact/vid/${id}/profile`;
};

export const GET_COMPANY_BY_ID = (id) => {
  return `${endPoint}/companies/v2/companies/${id}`;
};

export const GET_CONTACTS_OF_COMPANY = (id) => {
  return `${endPoint}/companies/v2/companies/${id}/contacts`;
};
